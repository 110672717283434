export type exchangeName =
  | 'productos'
  | 'experiencias'
  | 'giftcards'
  | 'viajes';

export enum maxCenteredCards {
  mobile = 2,
  tablet = 3,
  desktop = 3,
}

export type CardStyledProps = {
  exchangeActive?: boolean;
  exchangeName?: exchangeName;
};

export type ContainerProps = {
  numberOfCards?: number;
};
