import styled from 'styled-components';

const Styled = {
  Container: styled.section`
    width: 100%;
    padding: 48px 0px 48px 16px;
    background-color: var(--color-gray);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 48px 55px 48px 55px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 56px 84px 56px 84px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 56px 82px 56px 82px;
    }
  `,
};

export default Styled;
