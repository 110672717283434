import { consecutive, consecutiveAsc, consecutiveDesc } from '../shared';

const rangeNumber = (cellPhone: string): boolean => {
  const regex = /^(3[0-9][0-9])\d{7}$/;
  return regex.test(cellPhone);
};

const cellValidation = (cellPhone: string) => {
  const response = { valid: true, message: '' };
  const errorMessage = 'Número de celular no válido';

  if (!rangeNumber(cellPhone)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!consecutiveAsc(cellPhone, 5)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!consecutiveDesc(cellPhone, 5)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!consecutive(cellPhone)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  return response;
};

export default cellValidation;
