import { css } from 'styled-components';

export default css`
  :root {
    --color-default: #5c6166;
    --color-primary: #38a121;
    --color-bank: #1c7e04;
    --color-text-accent: #2b8f14;
    --color-primary-strong: #007a33;
    --color-bg-secundary: #3f3f3f;
    --color-bg-info: #f4f7f8;
    --color-bg-warning: #fef2e0;
    --color-bg-dark: #232426;
    --color-bg-error: #ffeaee;
    --color-bg-success: #e8f5e6;
    --color-bg-box: #ffffff;
    --color-bg-disabled: #eaeff4;
    --color-border-default: #c9d4df;
    --color-border-focus: #38a121;
    --color-border-error: #ed0025;
    --color-border-dark: #bac4cf;
    --color-border-yellow: #fbb34c;
    --color-border-tertiary: #c0ced6;
    --color-btn-primary: #38a121;
    --color-btn-text-primary: #ffffff;
    --color-btn-secondary: #eaeff4;
    --color-btn-text-secondary: #2b8f14;
    --color-btn-tertiary: #fff;
    --color-btn-text-tertiary: #44474b;
    --color-btn-cmr: #399624;
    --color-btn-text-cmr: #ffffff;
    --color-btn-disabled: #dbe3ec;
    --color-text-primary: #44474b;
    --color-text-cmr: #3ea327;
    --color-text-placeholder: #63696e;
    --color-text-disabled: #63696e;
    --color-error: #d3000b;
    --color-white: #ffffff;
    --color-danger: #db0025;
    --color-yellow: #c3d600;
    --color-gray: #f4f7f8;
    --color-gray-message-text: #8a9199;
    --color-gray-cmr: #e1e7ed;
    --color-green-alt: #2b8f14;
  }
`;
