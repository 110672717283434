// Definitions
import { MethodsDef } from '../Form.defs';

// Country forms
import cl from './cl';
import co from './co';
import pe from './pe';

// Form configuration list
const List = {
  cl,
  pe,
  co,
};

const COUNTRY = process.env.REACT_APP_COUNTRY as keyof typeof List;

function GetConfiguration() {
  const form = List[COUNTRY];
  const methods: MethodsDef = {
    getText: (
      type:
        | 'title'
        | 'subtitle'
        | 'titleCompleteProfile'
        | 'subtitleCompleteProfile'
        | 'titleDocumentType'
        | 'titleDocumentNumber'
        | 'titlePhone'
        | 'titleEmail',
    ) => form[type],
    getComponent: (id: string) =>
      form.components.find(component => component.id === id) ?? undefined,
    getCompleteProfileForm: (id: string) =>
      form.completeProfileForm?.find(input => input.id === id) ?? undefined,
    getFeedback: (code: string) =>
      form.feedback?.find(error => error.code === code) ?? undefined,
    getError: (code: string) => form.errors?.find(error => error.code === code),
  };
  return { form, methods };
}

export default GetConfiguration;
