import { Dispatch, SetStateAction } from 'react';

// Components
import Modal from '../Modal';
import StepsFormBrand from '../StepsFormBrand';

export type ModalFormBrandProps = {
  open: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const ModalFormBrand = ({ open, setOpenModal }: ModalFormBrandProps) => {
  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      closeOnBackgroundClick
      id="modal-form-brand"
      open={open}
      width="500px"
      rightIcon="close"
      onClose={handleModalClose}
      requireCloseAction={['rightIcon']}
      style={{ minHeight: '320px' }}>
      <StepsFormBrand handleModalClose={handleModalClose} />
    </Modal>
  );
};

export default ModalFormBrand;
