import { createContext, useContext, useEffect } from 'react';

import { ICustomerCategory } from 'domain/model';

// Stores
import UseContentfulStore from 'infrastructure/stores/Contentful';

// Utils
import { getWebpSupported } from 'infrastructure/utils/webp';

// Definitions
import {
  INotificationMessage,
  IShortCustomInformation,
} from 'domain/model/content';
import { ProviderState } from './Contentful.def';

// Default content
const Default: ProviderState = {
  data: {
    common: {
      isLoad: false,
      relatedCommercesContent: {} as any,
      interestLinksContent: {} as any,
      copyRightContent: {} as any,
      campaignProgram: {} as any,
      categoriesProgram: {} as any,
      companyDataContent: '',
      subscriptionTerms: {} as any,
      cookies: {} as any,
      loginIncentive: {} as any,
      siteDescription: {} as any,
    },
    mainSlider: {
      isLoad: false,
      content: {} as any,
    },
    home: {
      isLoad: false,
      outstandingExchangesTab: {} as any,
      sectionAccumulates: [] as any,
      sectionSubscribe: {} as any,
      categorySection: {} as any,
      exchangesSection: {
        name: '',
        cards: [],
        description: '',
      },
      sectionAcumulatesBanner: {} as any,
      brandSection: {
        name: '',
        cards: [],
      },
      challengeCardSection: {} as any,
      alliesSection: { name: '', cards: [], visible: false },
      giftcardNotification: {} as INotificationMessage,
      ecosystemSection: {
        name: '',
        cards: [],
      },
      ecosystemBanner: {
        name: '',
        nombre: '',
        mobile: {
          title: '',
          url: '',
        },
        desktop: {
          title: '',
          url: '',
        },
      },
      categoryAndBenefits: {
        title: '',
        description: '',
      } as IShortCustomInformation,
      newExchangesSection: {
        name: '',
        cards: [],
      },
      acumulationMorePointsSection: {
        name: '',
        cards: [],
      },
    },
    exchanges: {
      isLoad: false,
      productsTab: {} as any,
      couponsTab: {} as any,
      giftcardsTab: {} as any,
      storesCatalogsSection: {} as any,
      moreCouponsExchangesSection: {} as any,
    },
    myCategoryPageContent: {
      isLoad: false,
      title: '',
      subtitle: '',
      updateCategory: [] as any,
    },
    storesCatalogs: {
      isLoad: false,
      storesCatalogsSection: {} as any,
      seccionExchangeCards: {} as any,
    },
    landings: {
      isLoad: false,
      pageLandings: [],
    },
    app: {
      appRegistryBanner: {} as any,
    },
    categoryBenefits: {
      isLoad: false,
    } as any,
    helpCenter: {
      isLoad: false,
      isError: false,
      id: 0,
      title: '',
      titleHowToUse: '',
      stepsHowToUse: [],
      showHowToUse: false,
      categories: [],
      frequentQuestions: { id: 0, questions: [], subCategories: [] },
    },
    coupons: {
      isLoad: false,
      code: '',
      coupons: [],
      couponUseText: {} as any,
      legal: '',
    },
    exchangeExperience: {
      isLoad: false,
      exchangesSection: {} as any,
      typeSection: {} as any,
    },
  },
  methods: {
    LoadHome: async () => {},
    LoadExchanges: async () => {},
    LoadStoresCatalogs: async () => {},
    LoadLandings: async () => {},
    LoadCategory: async () => {},
    LoadMyCategoryPageContent: async () => {},
    LoadHelpCenter: async () => {},
    LoadCoupons: async () => {},
    resetCategory: () => {},
    LoadExchangeExperience: async () => {},
  },
};

const Provider = () => {
  const {
    loads,
    content: {
      common,
      mainSlider,
      home,
      exchanges,
      storesCatalogs,
      myCategoryPageContent,
      landings,
      categoryBenefits,
      app,
      helpCenter,
      coupons,
      exchangeExperience,
    },
    reset,
  } = UseContentfulStore();
  // Load common content
  useEffect(() => {
    const fetchCommonContent = async () => {
      await Promise.all([loads.common(), loads.mainSlider()]);
    };
    fetchCommonContent();
  }, [loads]);

  return {
    data: {
      common: {
        isLoad: common.isLoad,
        relatedCommercesContent: common.relatedCommerces,
        interestLinksContent: common.interestLinks,
        copyRightContent: common.copyRight,
        faq: common.faq,
        companyDataContent: common.companyData,
        notificationMessageContent: common.notificationMessage,
        campaignProgram: common.campaignProgram,
        categoriesProgram: common.categoriesProgram,
        subscriptionTerms: common.subscriptionTerms,
        cookies: common.cookies,
        loginIncentive: common.loginIncentive,
        siteDescription: common.siteDescription,
      },
      mainSlider,
      home: {
        isLoad: home.isLoad,
        exchangesSection: home.exchangesSection,
        outstandingExchangesTab: home.outstandingExchangesTab,
        sectionAccumulates: home.sectionAccumulates,
        sectionSubscribe: home.sectionSubscribe,
        categorySection: home.categorySection,
        sectionAcumulatesBanner: home.sectionAcumulatesBanner,
        brandSection: home.brandSection,
        challengeCardSection: home.challengeCardSection,
        alliesSection: home.alliesSection,
        giftcardNotification: home.giftcardNotification,
        ecosystemSection: home.ecosystemSection,
        ecosystemBanner: home.ecosystemBanner,
        categoryAndBenefits: home.categoryAndBenefits,
        newExchangesSection: home.newExchangesSection,
        acumulationMorePointsSection: home.acumulationMorePointsSection,
      },
      exchanges: {
        isLoad: exchanges.isLoad,
        productsTab: exchanges.productsTab,
        couponsTab: exchanges.couponsTab,
        giftcardsTab: exchanges.giftcardsTab,
        storesCatalogsSection: exchanges.storesCatalogsSection,
        moreCouponsExchangesSection: exchanges.moreCouponsExchangesSection,
      },
      storesCatalogs: {
        isLoad: storesCatalogs.isLoad,
        storesCatalogsSection: storesCatalogs.storesCatalogsSection,
        seccionExchangeCards: storesCatalogs.seccionExchangeCards,
      },
      myCategoryPageContent: {
        isLoad: myCategoryPageContent.isLoad,
        title: myCategoryPageContent.title,
        subtitle: myCategoryPageContent.subtitle,
        updateCategory: myCategoryPageContent.updateCategory,
      },
      landings: {
        isLoad: landings.isLoad,
        pageLandings: landings.pageLandings,
      },
      app: {
        appRegistryBanner: app.registryBanner,
      },
      categoryBenefits: {
        isLoad: categoryBenefits.isLoad,
        category: categoryBenefits.category,
        name: categoryBenefits.name,
        currentCategoryBenefits: categoryBenefits.currentCategoryBenefits,
        nextCategoryBenefits: categoryBenefits.nextCategoryBenefits,
      },
      helpCenter: { ...helpCenter },
      coupons: { ...coupons },
      exchangeExperience: { ...exchangeExperience },
    },
    methods: {
      LoadHome: async () => {
        await loads.home();
      },
      LoadExchanges: async () => {
        await loads.exchanges();
      },
      LoadStoresCatalogs: async () => {
        await loads.storesCatalogs();
      },
      LoadLandings: async () => {
        await loads.landings();
      },
      LoadMyCategoryPageContent: async () => {
        await loads.myCategoryPageContent();
      },
      LoadCategory: async (category: ICustomerCategory) => {
        await loads.categoryBenefits(category);
      },
      LoadHelpCenter: async () => {
        await loads.helpCenter();
      },
      LoadCoupons: async () => {
        await loads.coupons();
      },
      resetCategory: () => {
        reset.categoryBenefits();
      },
      LoadExchangeExperience: async () => {
        await loads.exchangeExperience();
      },
    },
  };
};

const Context = createContext(Default);

/**
 * Component provider
 * @param children
 * @return {*}
 * @constructor
 */
const ContentfulProvider = ({ children, ...props }: any) => {
  // Sets global webp format support information
  (globalThis as any).isWebpSupported = getWebpSupported();
  const { value } = props;

  return (
    <Context.Provider value={value || Provider()} {...props}>
      {children}
    </Context.Provider>
  );
};

/**
 * Component hook
 */
export const useContentful = () => useContext(Context);

export default ContentfulProvider;
