import { createGlobalStyle } from 'styled-components';

import Colors from './colors';
import Icons from './icons';

const globalStyles = createGlobalStyle`
  ${Colors};
  ${Icons};

  ::marker {
    font-size: 15px;
  }
  
  hr {
    height: 1px;
    border: 0;
    background: #ccc;
    margin-bottom:16px;
  }

  :root {
    --container-width: 1280px;
  }

  @-ms-viewport {
    width: device-width;
  }

  html {
    font-family: 'pfbeausans', Arial, sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    color: var(--color-default);
  }

  body {
    padding: 0px;
    margin: 0px;
    font-family: 'pfbeausans', Arial, sans-serif;
  }
`;

export default globalStyles;
