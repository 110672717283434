import { memo } from 'react';

import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Components
import { ICard } from 'domain/model/content';
import { Carousel } from 'presentation/components/molecules';
import SmartLink from 'presentation/components/organisms/SmartLink/SmartLink';

// Styled Components
import Styled from './AlliesSection.styled';

const breakpoints = {
  320: { slidesPerView: 1.5, spaceBetween: 24 },
  414: { slidesPerView: 1.7, spaceBetween: 24 },
  480: { slidesPerView: 2.5, spaceBetween: 24 },
  768: { slidesPerView: 3.5, spaceBetween: 24 },
  1024: { slidesPerView: 4.5, spaceBetween: 16 },
  1280: { slidesPerView: 5.5, spaceBetween: 16 },
  1600: { slidesPerView: 6.5, spaceBetween: 16 },
};

type AlliesSectionProps = {
  data: { name: string; cards: ICard[] };
};

const AlliesSection = ({ data }: AlliesSectionProps) => {
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  const handleCardClick = (cardName: string) => {
    publish({
      module: 'conoce_a_nuestros_aliados',
      action: 'card_ally_clicked',
      subject: `Card ${cardName} has been clicked`,
      target: cardName,
    });
  };

  return (
    <Styled.Container
      id="AlliesSection"
      numberOfCards={data.cards?.length || 0}>
      <Styled.Title>{data.name}</Styled.Title>
      <Styled.WrapperCardsGroup>
        <Carousel
          id="AlliesSection"
          style={{ position: 'relative' }}
          swiperOptions={{
            breakpoints,
            preventClicks: true,
            preventClicksPropagation: true,
            spaceBetween: 24,
            autoplay: { disableOnInteraction: false },
          }}>
          {data.cards.length > 0 &&
            data.cards?.map(card => (
              <SmartLink
                key={card.name}
                url={card.link?.url || ''}
                target={card.link?.external ? 'adaptable' : 'route'}
                mode="stock">
                <Styled.Card onClick={() => handleCardClick(card.name)}>
                  <img src={card.image.desktop.url} alt={card.image.name} />
                </Styled.Card>
              </SmartLink>
            ))}
        </Carousel>
      </Styled.WrapperCardsGroup>
    </Styled.Container>
  );
};

export default memo(AlliesSection);
