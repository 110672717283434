import styled, { css } from 'styled-components';
import {
  CardStyledProps,
  ContainerProps,
  maxCenteredCards,
} from './Exchanges.def';

const Styled = {
  Container: styled.section`
    width: 100%;
    padding: 48px 0px 0 16px;
    background-color: var(--color-gray);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 48px 55px 28px 55px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 56px 84px 36px 84px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 56px 82px 36px 82px;
    }
  `,
  WrapperSection: styled.div<ContainerProps>`
    max-width: 100%;
    margin: 0 auto;

    @media ${() => '(min-width: 1280px)'} {
      max-width: 954px;
    }

    @media ${() => '(min-width: 1360px)'} {
      max-width: 1194px;
    }

    @media ${() => '(min-width: 1930px)'} {
      max-width: 1434px;
    }

    .swiper-wrapper {
      ${({ numberOfCards }) =>
        numberOfCards &&
        numberOfCards <= maxCenteredCards.desktop &&
        css`
          @media ${({ theme }) =>
              theme.devices.desktop || theme.devices.wideDesktop} {
            justify-content: space-between;
          }
        `}
    }
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 22px;
    color: #323537;
    margin: 0 auto 24px;

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }
  `,

  ExchangeDescription: styled.p`
    font-size: 18px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #323537;
    margin-bottom: 24px;
    max-width: 80%;
    text-align: start;

    @media ${({ theme }) => theme.devices.tablet} {
      text-align: center;
      font-size: 24px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      text-align: center;
      font-size: 24px;
      margin: 0 auto 32px;
    }
  `,
  Card: styled.div<CardStyledProps>`
    display: flex;
    justify-content: start;
    align-items: start;
    background: #ffffff;
    border: 1px solid #ccd6e0;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 18px;
    max-width: 306px;
    height: 170px;
    max-height: 170px;
    width: 100%;
    overflow: hidden;

    @media ${({ theme }) => theme.devices.tablet} {
      max-width: 360px;
      height: 180px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 360px;
      height: 180px;
    }
  `,
  LeftColumnCard: styled.div`
    width: 100%;
    height: 100%;
    flex: 1.6;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  `,
  RightColumnCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    gap: 0.5rem;
    flex: 3;
  `,
  CardTitle: styled.div<StyleTitleCardProps>`
    font-size: 16px;
    color: ${props => colorsExchanges[props.background].background};
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.01em;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: 20px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 22px;
    }
  `,
  DescriptionCard: styled.p`
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    width: 100%;
    color: #474c4e;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: 16px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 16px;
    }
  `,
};

export default Styled;

export type Colorvariants =
  | 'orange'
  | 'green'
  | 'blue'
  | 'white'
  | 'skyblue'
  | 'purple'
  | 'yellow'
  | 'black';
type StyleTitleCardProps = {
  background: Colorvariants;
};

const colorsExchanges = {
  orange: {
    background: '#FF5000',
  },
  green: {
    background: '#43B02A',
  },
  blue: {
    background: '#0072CE',
  },
  white: {
    background: 'white',
  },
  skyblue: {
    background: '#1DA1F2',
  },
  purple: {
    background: '#DD3275',
  },
  yellow: {
    background: '#FFB600',
  },
  black: {
    background: '#FFB600',
  },
};
