type DomainConfig = Record<string, Record<string, RegExp | string | number>>;

export const domainConfig: DomainConfig = {
  gmail: {
    regex: /(g|m|a|i|l)/gi,
    domain: 'gmail',
    errorRate: 1,
    maxLengthCustomWord: 6,
    maxCoincidences: 4,
  },
  outlook: {
    regex: /(o|u|t|l|oo|k)/gi,
    domain: 'outlook',
    errorRate: 1,
    maxLengthCustomWord: 8,
    maxCoincidences: 6,
  },
  yahoo: {
    regex: /(y|a|h|oo|o)/gi,
    domain: 'yahoo',
    errorRate: 1,
    maxLengthCustomWord: 6,
    maxCoincidences: 4,
  },
  hotmail: {
    regex: /(h|o|t|m|a|i|l)/gi,
    domain: 'hotmail',
    errorRate: 1,
    maxLengthCustomWord: 8,
    maxCoincidences: 6,
  },
};

export const domainValidation = (
  word: string,
  configValidation: Record<string, RegExp | string | number>,
) => {
  const { regex, domain, errorRate, maxLengthCustomWord, maxCoincidences } =
    configValidation;

  const cleanWord = word
    .match(regex as RegExp)
    ?.filter((item, pos) => word.match(regex as RegExp)?.indexOf(item) === pos)
    .join('');

  const matchWord = word.match(regex as RegExp)?.join('') || '';

  // mismo Largo pero se equivoca en x letra
  if (
    Math.abs(matchWord.length - domain.toString().length) <=
      Number(errorRate) &&
    domain !== word &&
    domain.toString().length === word.length
  ) {
    return false;
  }

  // Largo diferente pero contiene la palabra
  if (
    domain.toString().length !== word.length &&
    domain === matchWord &&
    Number(maxLengthCustomWord) + 1 === domain.length
  ) {
    return false;
  }

  // Largo diferente pero contiene parte de la palabra
  if (
    domain.toString.length !== word.length &&
    word.length <= Number(maxLengthCustomWord) &&
    matchWord.length === maxCoincidences
  ) {
    if (domain === 'gmail' && !matchWord.includes('g')) {
      return true;
    }
    return false;
  }

  // Largo máximo permitido pero tiene una letra de más
  if (cleanWord === domain && word.length === maxLengthCustomWord) {
    return false;
  }

  return true;
};

export const repeatingNumbers = (
  word: string,
  lengthText: number,
  maxRepeat: number,
): boolean => {
  const numberCount: number[] = new Array(10).fill(0);

  const textCopy = word.split('');

  if (textCopy?.length !== lengthText) return false;

  textCopy.forEach(element => {
    numberCount[Number(element)] = numberCount[Number(element)] + 1;
  });
  const maxValue = Math.max(...numberCount);

  return maxRepeat >= maxValue;
};

export const excludeWords = (word: string, arrayExclude: string[]) => {
  const stringRegex = `^((?!${arrayExclude.join('|')}).)*$`;
  const regex = new RegExp(stringRegex);

  const prefixEmail = word?.split('@')[0]?.toLowerCase();

  return regex.test(prefixEmail);
};

export const excludeDomainEmail = (
  email: string,
  domainExclude: string[],
): boolean => {
  const stringRegex = `^((?!${domainExclude.join('|')}).)*$`;
  const regex = new RegExp(stringRegex);
  return regex.test(email);
};

export const consecutiveAsc = (word: string, maxRepeat: number): boolean => {
  const stringRegex = `^(?!.*(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){${maxRepeat},})`;
  const regex = new RegExp(stringRegex);

  return regex.test(word);
};

export const consecutiveDesc = (word: string, maxRepeat: number): boolean => {
  const stringRegex = `^(?!.*(?:9(?=8)|8(?=7)|7(?=6)|6(?=5)|5(?=4)|4(?=3)|3(?=2)|2(?=1)|1(?=0)){${maxRepeat},})`;

  const regex = new RegExp(stringRegex);
  return regex.test(word);
};

export const consecutive = (cellPhone: string): boolean => {
  const regex = /^(?!\d*(\d)\1{4})/;
  return regex.test(cellPhone);
};
