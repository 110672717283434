import styled from 'styled-components';

const Styled = {
  WrapperSlider: styled.div`
    width: 100%;
  `,
  WrapperExpirationPoints: styled.div`
    padding: 16px;

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 32px 5% 32px 5%;
    }
  `,
  WrapperChallengeCards: styled.section`
    width: 100%;
    padding: 48px 0px 48px 24px;
    background-color: #f4f7f9;
    height: fit-content;

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 88px 20px 93px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 88px 81px 93px;
    }
  `,
};

export default Styled;
