import * as React from 'react';
import {
  useState,
  forwardRef,
  ForwardedRef,
  MutableRefObject,
  ReactElement,
  ComponentProps,
  ChangeEvent,
} from 'react';

// Definitions
type Props = {
  input: ReactElement<ComponentProps<'input'>>;
  formatter: (value: string) => string;
};

const InputFormat = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { input, formatter } = props;
  const {
    props: { onChange },
  } = input;

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>();

  const handleRef = (element: HTMLInputElement | null) => {
    if (!element) return;

    if (typeof ref === 'function') {
      ref(element);
    }

    const refElement =
      (ref as MutableRefObject<HTMLInputElement>)?.current ?? element;

    refElement.value = formatter(refElement.value);
    setInputElement(refElement);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!inputElement) return;

    const { value } = event.target;

    const formattedValue = formatter(value);
    inputElement.value = formattedValue;

    onChange?.(event);
  };

  return React.cloneElement(input, {
    ref: handleRef,
    onChange: handleOnChange,
  });
};

export default forwardRef(InputFormat);
