import { CustomerCategoryName } from 'domain/model';

import { categoryAndBenefitsProps } from './categoryAndBenefits.def';

const categoryAndBenefits = {
  categoryList: [
    {
      type: CustomerCategoryName.Fan,
      name: 'Fan',
      icon: 'fan',
      points: '5.000',
      glosa: 'en nuestras tiendas o comprando $900.000 en ellas',
      benefitsList: [
        {
          id: 1,
          icon: 'shopping',
          sizeIcon: 'tiny',
          text: 'Canje de productos, experiencias y Gift Cards',
        },
        {
          id: 2,
          icon: 'offersWhite',
          sizeIcon: 'tiny',
          text: 'Beneficios en el mes de tu cumpleaños',
        },
        {
          id: 3,
          icon: 'fanLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Fan:',
          link: {
            url: 'https://www.cmrpuntos.cl/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
    } as categoryAndBenefitsProps,
    {
      type: CustomerCategoryName.Premium,
      name: 'Premium',
      icon: 'premium',
      points: '25.000',
      glosa: 'en compras o abriendo tu Tarjeta CMR Premium',
      benefitsList: [
        {
          id: 1,
          icon: 'physicalCard',
          sizeIcon: 'tiny',
          text: 'Dcto. en administración de tu Tarjeta CMR Falabella*',
        },
        {
          id: 2,
          icon: 'date',
          sizeIcon: 'tiny',
          text: 'Tus CMR Puntos duran 2 años',
        },
        {
          id: 3,
          icon: 'premiumLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Premium:',
          link: {
            url: 'https://www.cmrpuntos.cl/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
      cta: {
        text: 'Elige ser Premium',
        url: 'https://www.bancofalabella.cl/tarjetas-credito-cmr/mastercard-premium',
        target: 'blank',
      },
    } as categoryAndBenefitsProps,
    {
      type: CustomerCategoryName.Elite,
      name: 'Elite',
      icon: 'elite',
      points: '60.000',
      glosa: 'en compras o abriendo tu Tarjeta CMR Elite',
      benefitsList: [
        {
          id: 1,
          icon: 'shipping',
          sizeIcon: 'tiny',
          text: '3 despachos gratis al mes en',
          hasIconFcom: true,
          description: 'Exclusivo pagando con CMR Falabella',
        },
        {
          id: 2,
          icon: 'product',
          sizeIcon: 'tiny',
          text: 'Costo $0 en mantención de tu Cuenta Corriente Banco Falabella*',
        },
        {
          id: 4,
          icon: 'eliteLine',
          sizeIcon: 'small',
          outlineIcon: true,
          text: 'Y más beneficios de la Categoría Elite:',
          link: {
            url: 'https://www.cmrpuntos.cl/categorias',
            target: 'blank',
            text: 'Descubre todo aquí',
          },
        },
      ],
      cta: {
        text: 'Elige ser Elite',
        url: 'https://www.bancofalabella.cl/tarjetas-credito-cmr/mastercard-elite',
        target: 'blank',
      },
    } as categoryAndBenefitsProps,
  ],
};

export default categoryAndBenefits;
