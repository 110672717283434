import styled, { css } from 'styled-components';
import SmartLink from 'presentation/components/organisms/SmartLink';
import {
  DynamicContainerDef,
  TextDef,
  ButtonContainerDef,
  OverlayProps,
  BackgroundImgProps,
} from './Slide.def';

const SLIDE_HEIGHT = '448px';

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  width: 100%;
  height: ${SLIDE_HEIGHT};
  top: 0;
  right: 0;

  ${props => {
    switch (props.color) {
      case 'light':
        return css`
          background: -moz-linear-gradient(
            left,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        `;
      default:
        return css`
          background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        `;
    }
  }}
`;
export const Container = styled.div<DynamicContainerDef>`
  width: 100%;
  user-select: none;
  position: relative;
`;

export const Link = styled(SmartLink)<DynamicContainerDef>`
  color: ${({ color }) => (color === 'dark' ? 'dark' : 'white')};
  line-height: initial;
  position: relative;
`;

export const Background = styled.img<BackgroundImgProps>`
  object-fit: cover;
  object-position: ${({ mobile }) =>
    mobile ? 'center center' : 'right center'};
  width: 100%;
  overflow: hidden;
  height: ${SLIDE_HEIGHT};
`;

export const Title = styled.h6<TextDef>`
  width: 100%;
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
  ${props => {
    const { color } = props;
    return `
      color: ${color === 'dark' ? '#44474B' : '#FFFFFF'};
    `;
  }};
`;

export const Text = styled.p<TextDef>`
  width: 90%;
  font-weight: 100;
  font-size: 28px;
  line-height: 40px;
  ${props => {
    const { color } = props;
    return `
      color: ${color === 'dark' ? '#44474B' : 'white'};
    `;
  }};
`;

export const ButtonContainer = styled.div<ButtonContainerDef>`
  width: 100%;
  ${props => {
    const { mobile, highDesktop } = props;
    const methods = {
      marginTop: () => {
        if (mobile) return '3rem';
        return '1.35rem';
      },
      fontSize: () => {
        if (mobile) return '0.7rem';
        if (highDesktop) return '1rem';
        return 'auto';
      },
    };
    return `
      button {
        margin-top: ${methods.marginTop()};
        font-size: ${methods.fontSize()};
      }
    `;
  }};
`;

export const TextContainer = styled.div<TextDef>`
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ mobile }) => (mobile ? 'end' : 'center')};
  padding-bottom: ${({ mobile }) => (mobile ? '50px' : '0')};
  flex-direction: column;
  ${props => {
    const { mobile } = props;
    const methods = {
      width: () => {
        if (mobile) return 'calc(100% - 2rem)';
        return '50%';
      },
      marginLeft: () => {
        if (mobile) return '1rem';
        return '4rem';
      },
      lineHeight: () => {
        if (mobile) return '1.5rem';
        return '2.8rem';
      },
    };

    return `
      margin-left: ${methods.marginLeft()};
      width: ${methods.width()};
      line-height: ${methods.lineHeight()};
    `;
  }}
`;
