import { Carousel } from 'presentation/components/molecules';
import { OfferCardType } from 'presentation/components/molecules/OfferCard/OfferCard.def';
import OfferCard from 'presentation/components/molecules/OfferCard/OfferCard';

import { ICard } from 'domain/model/content';
import Styled from './AcumulateMorePoints.style';

const breakpoints = {
  320: { slidesPerView: 1.1, spaceBetween: 7 },
  375: { slidesPerView: 1.2, spaceBetween: 7 },
  425: { slidesPerView: 1.1, spaceBetween: 16 },
  768: { slidesPerView: 1.5, spaceBetween: 16 },
  1024: { slidesPerView: 2.1, spaceBetween: 16 },
  1280: { slidesPerView: 3, spaceBetween: 16 },
  1440: { slidesPerView: 3, spaceBetween: 16 },
  1700: { slidesPerView: 4, spaceBetween: 16 },
};

type Props = {
  data: Array<ICard>;
};

const AcumulateMorePoints = (props: Props) => {
  const { data: listCards } = props;

  return (
    <>
      <Styled.Header>
        <Styled.Title>Acumula más CMR Puntos</Styled.Title>
      </Styled.Header>
      <Styled.Content cardNumer={listCards.length}>
        <Carousel
          id="AcumulateMorePointsCarousel"
          swiperOptions={{ breakpoints, navigation: true }}
          handleActiveIndexChange={() => null}>
          {listCards.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Styled.WrapperCard key={index + 1}>
              <OfferCard
                width="auto"
                height="100%"
                heightImage="148px"
                data={
                  {
                    image: item.image.mobile.url,
                    title: item.title?.desktop,
                    titleMobile: item?.title?.mobile,
                    subtitle: item.description,
                    cta: item.link?.text,
                    url: item.link?.url,
                    target: item.link?.external ? 'blank' : 'self',
                  } as OfferCardType
                }
              />
            </Styled.WrapperCard>
          ))}
        </Carousel>
      </Styled.Content>
    </>
  );
};

export default AcumulateMorePoints;
