import styled, { css } from 'styled-components';

const Styled = {
  Header: styled.div`
    padding: 32px 24px 0;
    color: #323537;
    text-align: center;
  `,
  Title: styled.h2`
    font-weight: 400;
    font-size: 22px;
    color: #323537;
    margin: 0 auto 24px;

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto;
    }
  `,
  Content: styled.div<{ cardNumer: number }>`
    padding: 36px 24px 40px;

    .swiper {
      .swiper-wrapper {
        min-height: 100%;
      }

      .swiper-slide {
        height: auto;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding-left: 80px;
      padding-right: 80px;
    }

    max-width: 100%;
    margin: 0 auto;

    @media ${() => '(min-width: 1930px)'} {
      max-width: 1680px;
    }

    ${({ cardNumer }) =>
      cardNumer &&
      cardNumer <= 3 &&
      css`
        @media ${() => '(min-width: 1280px)'} {
          .swiper {
            .swiper-wrapper {
              justify-content: center;
            }
          }
        }
      `}
    padding-right: ${({ theme }) => (theme.devices.mobile ? '0' : '24px')};
  `,
  WrapperCard: styled.div`
    @media ${() => '(max-width: 424px)'} {
      width: 272px;
    }
    @media ${() => '(min-width: 425px)'} {
      width: 360px;
    }
    height: 100%;
  `,
};

export default Styled;
