import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Definition
type RenderProps = {
  width?: string;
  height?: string;
  background?: string;
  margin?: string;
  padding?: string;
  boxShadow?: string;
  borderColor?: string;
};

export type Props = {
  children: ReactNode | string;
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
  background?: string;
  testId?: string;
  boxShadow?: string;
  borderColor?: string;
};

const Card = (props: Props) => {
  const {
    children,
    width,
    height,
    margin,
    padding,
    background,
    testId,
    boxShadow,
    borderColor,
  } = props;
  return (
    <Render
      data-testid={testId}
      width={width}
      height={height}
      background={background}
      margin={margin}
      padding={padding}
      boxShadow={boxShadow}
      borderColor={borderColor}>
      {children}
    </Render>
  );
};

// Style
const Render = styled.div<RenderProps>`
  width: ${({ width }) => width ?? 'inherit'};
  height: ${({ height }) => height ?? 'inherit'};
  margin: ${({ margin }) => margin ?? 'inherit'};
  padding: ${({ padding }) => padding ?? 'inherit'};
  background: ${({ background }) => background ?? '#ffffff'};
  border: 0.5px solid #c0ced6;
  border-color: ${({ borderColor }) => borderColor ?? '#cfdbe6'};
  box-sizing: border-box;
  box-shadow: ${({ boxShadow }) =>
    boxShadow ?? '0px 4px 2px rgba(0, 51, 102, 0.05)'};
  border-radius: 16px;
  overflow: hidden;
`;

export default Card;
