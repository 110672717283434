import { ICard } from 'domain/model/content';
import { Carousel } from 'presentation/components/molecules';
import Image from 'presentation/components/atoms/Image';

// hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

import truncateText from 'presentation/utils/truncateText';
import SmartLink from '../SmartLink/SmartLink';

// Styles
import Styled, { Colorvariants } from './Exchanges.styles';

// utils
import useExchangesUtils from './Exchanges.utils';
import { exchangeName } from './Exchanges.def';

const breakpoints = {
  300: { slidesPerView: 1.2 },
  375: { slidesPerView: 1.2 },
  414: { slidesPerView: 1.4 },
  460: { slidesPerView: 1.5 },
  600: { slidesPerView: 1.8 },
  660: { slidesPerView: 2.2 },
  700: { slidesPerView: 2.2 },
  800: { slidesPerView: 2.3 },
  900: { slidesPerView: 2.4 },
  1124: { slidesPerView: 3 },
  1930: { slidesPerView: 4 },
};

const MAX_LENGTH_TITLE = 22;

// Definitions
type Props = {
  cards?: ICard[];
  title?: string;
  description?: string;
  exchangeSelected?: string;
};

const Exchanges = (props: Props) => {
  // Props
  const { cards, title, exchangeSelected, description } = props;

  const isMobile = useIsMobile();
  // Utils
  const { methods } = useExchangesUtils();

  const MAX_LENGTH_TEXT = isMobile ? 60 : 90;

  // Handlers
  const handleCardClick = (card: string) => {
    methods.on.cardClick(card, exchangeSelected || '');
  };

  return (
    <Styled.Container>
      <Styled.WrapperSection numberOfCards={cards?.length || 0}>
        <Styled.Title>{title || '¿Qué quieres canjear hoy?'}</Styled.Title>
        {description ? (
          <Styled.ExchangeDescription>{description}</Styled.ExchangeDescription>
        ) : null}
        <Carousel
          style={{ position: 'relative' }}
          id="exchanges"
          customNavigation={{
            isNavigation: true,
            nextEl: 'exchanges-swiper-button-next',
            prevEl: 'exchanges-swiper-button-prev',
          }}
          swiperOptions={{
            breakpoints,
            navigation: {
              nextEl: '.exchanges-swiper-button-next',
              prevEl: '.exchanges-swiper-button-prev',
            },
            spaceBetween: 16,
          }}>
          {cards?.map(card => (
            <SmartLink
              key={card.name}
              url={card.link?.url || ''}
              target={card.link?.external ? 'adaptable' : 'route'}
              mode="stock">
              <Styled.Card
                exchangeActive={
                  exchangeSelected ===
                  card.name.toLowerCase().replace(/\s+/g, '')
                }
                exchangeName={
                  card.name.toLowerCase().replace(/\s+/g, '') as exchangeName
                }
                onClick={() => handleCardClick(card.name)}
                data-testid={card.name}>
                <Styled.LeftColumnCard>
                  <Image
                    source={card.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'fill',
                    }}
                  />
                </Styled.LeftColumnCard>
                <Styled.RightColumnCard>
                  <Styled.CardTitle
                    background={(card?.color as Colorvariants) || 'black'}>
                    {truncateText(card.name, MAX_LENGTH_TITLE)}
                  </Styled.CardTitle>

                  <Styled.DescriptionCard>
                    {truncateText(card.description, MAX_LENGTH_TEXT)}
                  </Styled.DescriptionCard>
                </Styled.RightColumnCard>
              </Styled.Card>
            </SmartLink>
          ))}
        </Carousel>
      </Styled.WrapperSection>
    </Styled.Container>
  );
};

export default Exchanges;
