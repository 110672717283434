import styled from 'styled-components';

// Styles mixins
import {
  TextDefaultFontMixin,
  TextSizeMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';

export const FormControl = styled.div``;

export const FormControlLabel = styled.label`
  ${TextDefaultFontMixin};
  ${TextSizeMixin('large')};

  display: block;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const FormControlHintText = styled.p`
  ${TextDefaultFontMixin};
  ${TextSizeMixin('small')};

  line-height: 175%;
  margin-bottom: 6px;

  & > strong {
    font-weight: 600 !important;
  }
`;

export const FormControlMessage = styled.div.attrs({
  role: 'alert',
})`
  ${TextSizeMixin('x-small')};
  ${TextWeightMixin('regular')};

  padding-top: 8px;
  padding-bottom: 16px;
  line-height: 1;
  color: var(--color-error);

  & > p {
    height: 1em;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  &[aria-hidden] > p {
    height: 0;
    opacity: 0;
  }
`;
