import Icon from 'presentation/assets/icons';
import SmartLink from 'presentation/components/organisms/SmartLink';
import { categoryAndBenefitsProps } from 'domain/configuration/categoryAndBenefits';
import Button from 'presentation/components/atoms/Button';
import useIsMobile from 'infrastructure/hooks/IsMobile/IsMobile';
import Styled from './CategoryAndBenefitsCard.style';

const country = process.env.REACT_APP_COUNTRY;
const CategoryAndBenefitsCard = (props: categoryAndBenefitsProps) => {
  const { type, name, icon, points, glosa, benefitsList, cta } = props;
  const isMobile = useIsMobile();

  return (
    <Styled.WrapperCard>
      <Styled.Card>
        <Styled.Header type={type}>
          <Styled.BadgeCategory>
            {name}
            <Icon
              size={isMobile ? 'medium' : 'large'}
              name={icon}
              style={{ height: 40 }}
            />
          </Styled.BadgeCategory>
          <div>
            <Styled.AccumulatingText>Acumulando</Styled.AccumulatingText>
            <Styled.WrapperPoints>
              <Styled.Points>{points}</Styled.Points>
              <Icon name="cmr" size="large" style={{ height: 50 }} />
            </Styled.WrapperPoints>
            <Styled.AcumulationInformationText>
              {glosa}
            </Styled.AcumulationInformationText>
          </div>
        </Styled.Header>
        <Styled.Content>
          <Styled.BenefitsList>
            {benefitsList &&
              benefitsList.map(item => (
                <Styled.BenefitsItem
                  key={item.id}
                  country={country || ''}
                  type={type}>
                  <Styled.WrapperIcon
                    type={type}
                    outlineIcon={item.outlineIcon}>
                    <Icon size={item.sizeIcon} name={item.icon} />
                  </Styled.WrapperIcon>
                  <Styled.BenefitsText>
                    {item.text}
                    {item.hasIconFcom ? <Icon name="fcom" /> : null}
                    {item.secondText && item.secondText}
                    {item.description && (
                      <Styled.DescriptionItem>
                        {item.description}
                      </Styled.DescriptionItem>
                    )}

                    {item.link ? (
                      <SmartLink url={item.link.url} target="blank">
                        {item.link.text}
                      </SmartLink>
                    ) : null}
                  </Styled.BenefitsText>
                </Styled.BenefitsItem>
              ))}
          </Styled.BenefitsList>
        </Styled.Content>
        {cta ? (
          <Styled.Footer>
            <SmartLink url={cta.url} target="blank">
              <Button>{cta.text}</Button>
            </SmartLink>
          </Styled.Footer>
        ) : null}
      </Styled.Card>
    </Styled.WrapperCard>
  );
};

export default CategoryAndBenefitsCard;
