import { createState, useState } from '@hookstate/core';

import { ICustomerCategory } from 'domain/model';

// Models
import {
  ICategoryHelpCenter,
  ICampaignCard,
  IFaqQuestion,
  IImageResponsive,
  INotificationMessage,
  ISpecialContent,
  IFrequentQuestionsHelpCenter,
  ICouponsGroup,
  ICouponUseText,
  IRichtext,
  ICmrCopyright,
} from 'domain/model/content';

// Contentful loaders
import {
  CommonLoader,
  MainSliderLoader,
  HomeLoader,
  ExchangesLoader,
  StoresCatalogsLoader,
  LandingsLoader,
  CategoryBenefitsLoader,
  HelpCenterLoader,
  CouponsLoader,
  ExchangeExperienceLoader,
  MyCategoryPageContentLoader,
} from './loaders';

import { ContenfulState } from './Contenful.def';

// State
const Default: ContenfulState = {
  common: {
    isLoad: false,
    relatedCommerces: {} as any,
    interestLinks: [],
    rights: '',
    companyData: '',
    notificationMessage: {} as any,
    campaignProgram: {} as any,
    categoriesProgram: {} as any,
    appRegistryBanner: {} as any,
    faq: undefined,
    subscriptionTerms: {} as any,
    cookies: {} as any,
    loginIncentive: {} as any,
    siteDescription: {} as any,
  },
  home: {
    isLoad: false,
    outstandingExchangesTab: {} as any,
    categorySection: {} as any,
    exchangesSection: {
      name: '',
      cards: [],
      description: '',
    },
    faq: undefined,
    sectionAccumulates: [] as any,
    sectionSubscribe: {} as any,
    sectionAcumulatesBanner: {} as any,
    brandSection: {
      name: '',
      cards: [],
    },
    challengeCardSection: {} as any,
    alliesSection: { name: '', cards: [], visible: false },
    giftcardNotification: {} as INotificationMessage,
    ecosystemSection: {
      name: '',
      cards: [],
    },
    ecosystemBanner: {
      name: '',
      nombre: '',
      mobile: {
        title: '',
        url: '',
      },
      desktop: {
        title: '',
        url: '',
      },
    },
    categoryAndBenefits: {} as any,
    newExchangesSection: {
      name: '',
      cards: [],
    },
    acumulationMorePointsSection: {
      name: '',
      cards: [],
    },
  },
  exchanges: {
    isLoad: false,
    productsExchangeTab: {} as any,
    couponsExchangeTab: {} as any,
    giftcardsExchangeTab: {} as any,
    storesCatalogsSection: {} as any,
    moreCouponsExchangesSection: {} as any,
  },
  myCategoryPageContent: {
    isLoad: false,
    title: '',
    subtitle: '',
    updateCategory: [] as any,
  },
  storesCatalogs: {
    isLoad: false,
    storesCatalogsSection: {} as any,
    seccionExchangeCards: {} as any,
  },
  landings: {
    isLoad: false,
    pageLandings: [],
  },
  mainSlider: {
    isLoad: false,
    content: {} as any,
  },
  categoryBenefits: {
    isLoad: false,
    category: '',
    name: '',
    currentCategoryBenefits: {} as any,
    nextCategoryBenefits: {} as any,
  },
  helpCenter: {
    isLoad: false,
    isError: false,
    id: 0,
    title: '',
    titleHowToUse: '',
    stepsHowToUse: [],
    showHowToUse: false,
    categories: [],
    frequentQuestions: { id: 0, questions: [], subCategories: [] },
  },
  coupons: {
    isLoad: false,
    code: '',
    coupons: [],
    couponUseText: {} as any,
    legal: '',
  },
  exchangeExperience: {
    isLoad: false,
    exchangesSection: {} as any,
    typeSection: {} as any,
  },
};

const state = createState({ ...Default });

/**
 * Store constructor
 * @constructor
 */
export default function UseContentfulStore() {
  // Current state
  const current = useState(state);
  return {
    loads: {
      common: async () => {
        if (current.common.isLoad.value) return;

        const content = await CommonLoader();

        if (!content) return;

        current.common.set({ isLoad: true, ...content });
      },
      home: async () => {
        if (current.home.isLoad.value) return;

        const content = await HomeLoader();

        if (!content) return;

        current.home.set({ isLoad: true, ...content });
      },
      exchanges: async () => {
        if (current.exchanges.isLoad.value) return;

        const content = await ExchangesLoader();
        if (!content) return;

        current.exchanges.set({ isLoad: true, ...content });
      },
      storesCatalogs: async () => {
        if (current.myCategoryPageContent.isLoad.value) return;

        const content = await StoresCatalogsLoader();
        if (!content) return;

        current.storesCatalogs.set({ isLoad: true, ...content });
      },
      myCategoryPageContent: async () => {
        if (current.myCategoryPageContent.isLoad.value) return;

        const content = await MyCategoryPageContentLoader();
        if (!content) return;

        current.myCategoryPageContent.set({ isLoad: true, ...content });
      },
      landings: async () => {
        if (current.landings.isLoad.value) return;

        const content = await LandingsLoader();
        if (!content) return;

        current.landings.set({ isLoad: true, ...content });
      },
      mainSlider: async () => {
        if (current.home.isLoad.value) return;

        const content = await MainSliderLoader();
        if (!content) return;

        current.mainSlider.set({ isLoad: true, ...content });
      },
      categoryBenefits: async (category: ICustomerCategory) => {
        const content = await CategoryBenefitsLoader(category);
        if (!content) return;
        current.categoryBenefits.set({
          isLoad: true,
          ...content.categoryBenefits,
        });
      },
      helpCenter: async () => {
        if (current.helpCenter.isLoad.value) return;

        const content = await HelpCenterLoader();

        if (!content) {
          return current.helpCenter.set({
            ...Default.helpCenter,
            isLoad: true,
            isError: true,
          });
        }

        current.helpCenter.set({
          isLoad: true,
          isError: false,
          ...content,
        });
      },
      coupons: async () => {
        if (current.coupons.isLoad.value) return;
        const content = await CouponsLoader();

        if (!content) return;
        current.coupons.set({ isLoad: true, ...content });
      },
      exchangeExperience: async () => {
        if (current.exchangeExperience.isLoad.value) return;

        const content = await ExchangeExperienceLoader();

        if (!content) return;

        current.exchangeExperience.set({ isLoad: true, ...content });
      },
    },
    content: {
      common: {
        get isLoad() {
          return current.common.isLoad.value;
        },
        get relatedCommerces() {
          return current.common.relatedCommerces.value;
        },
        get interestLinks() {
          return current.common.interestLinks.value;
        },
        get copyRight(): ICmrCopyright {
          return { text: current.common.rights.value };
        },
        get companyData(): string {
          return current.common.companyData.value;
        },
        get notificationMessage(): INotificationMessage | undefined {
          return current.common.notificationMessage?.value;
        },
        get faq(): IFaqQuestion[] | undefined {
          return current.common.faq.value;
        },
        get campaignProgram(): ICampaignCard {
          return current.common.campaignProgram.value;
        },
        get categoriesProgram(): IImageResponsive {
          return current.common.categoriesProgram.value;
        },
        get subscriptionTerms(): IRichtext {
          return current.common.subscriptionTerms.value;
        },
        get cookies(): IRichtext {
          return current.common.cookies.value;
        },
        get loginIncentive(): ISpecialContent {
          return current.common.loginIncentive.value;
        },
        get siteDescription(): string {
          return current.common.siteDescription.value;
        },
      },
      home: {
        get isLoad() {
          return current.home.isLoad.value;
        },
        get outstandingExchangesTab() {
          return current.home.outstandingExchangesTab.value;
        },
        get categorySection() {
          return current.home.categorySection.value;
        },
        get exchangesSection() {
          return current.home.exchangesSection.value;
        },
        get sectionAccumulates() {
          return current.home.sectionAccumulates.value;
        },
        get sectionAcumulatesBanner() {
          return current.home.sectionAcumulatesBanner.value;
        },
        get sectionSubscribe() {
          return current.home.sectionSubscribe.value;
        },
        get brandSection() {
          return current.home.brandSection.value;
        },
        get challengeCardSection() {
          return current.home.challengeCardSection.value;
        },
        get alliesSection() {
          return current.home.alliesSection.value;
        },
        get giftcardNotification() {
          return current.home.giftcardNotification.value;
        },
        get ecosystemSection() {
          return current.home.ecosystemSection.value;
        },
        get ecosystemBanner() {
          return current.home.ecosystemBanner.value;
        },
        get categoryAndBenefits() {
          return current.home.categoryAndBenefits.value;
        },
        get newExchangesSection() {
          return current.home.newExchangesSection.value;
        },
        get acumulationMorePointsSection() {
          return current.home.acumulationMorePointsSection.value;
        },
      },
      exchanges: {
        get isLoad() {
          return current.exchanges.isLoad.value;
        },
        get productsTab() {
          return current.exchanges.productsExchangeTab.value;
        },
        get couponsTab() {
          return current.exchanges.couponsExchangeTab.value;
        },
        get giftcardsTab() {
          return current.exchanges.giftcardsExchangeTab.value;
        },
        get storesCatalogsSection() {
          return current.exchanges.storesCatalogsSection.value;
        },
        get moreCouponsExchangesSection() {
          return current.exchanges.moreCouponsExchangesSection.value;
        },
      },
      storesCatalogs: {
        get isLoad() {
          return current.storesCatalogs.isLoad.value;
        },
        get storesCatalogsSection() {
          return current.storesCatalogs.storesCatalogsSection.value;
        },
        get seccionExchangeCards() {
          return current.storesCatalogs.seccionExchangeCards.value;
        },
      },
      myCategoryPageContent: {
        get isLoad() {
          return current.myCategoryPageContent.isLoad.value;
        },
        get title() {
          return current.myCategoryPageContent.title.value;
        },
        get subtitle() {
          return current.myCategoryPageContent.subtitle.value;
        },
        get updateCategory() {
          return current.myCategoryPageContent.updateCategory.value;
        },
      },
      landings: {
        get isLoad() {
          return current.landings.isLoad.value;
        },
        get pageLandings() {
          return current.landings.pageLandings.value;
        },
      },
      mainSlider: {
        get isLoad() {
          return current.mainSlider.isLoad.value;
        },
        get content() {
          return current.mainSlider.content.value;
        },
      },
      app: {
        get registryBanner(): IImageResponsive {
          return current.common.appRegistryBanner.value;
        },
      },
      categoryBenefits: {
        get isLoad() {
          return current.categoryBenefits.isLoad.value;
        },
        get category() {
          return current.categoryBenefits.category.value;
        },
        get name() {
          return current.categoryBenefits.name.value;
        },
        get currentCategoryBenefits() {
          return current.categoryBenefits.currentCategoryBenefits.value;
        },
        get nextCategoryBenefits() {
          return current.categoryBenefits.nextCategoryBenefits.value;
        },
      },
      helpCenter: {
        get isLoad() {
          return current.helpCenter.isLoad.value;
        },
        get isError() {
          return current.helpCenter.isError.value;
        },
        get id() {
          return current.helpCenter.id.value;
        },
        get title() {
          return current.helpCenter.title.value;
        },
        get titleHowToUse() {
          return current.helpCenter.titleHowToUse.value;
        },
        get stepsHowToUse() {
          return current.helpCenter.stepsHowToUse.value;
        },
        get showHowToUse() {
          return current.helpCenter.showHowToUse.value;
        },
        get categories(): ICategoryHelpCenter[] {
          return current.helpCenter.categories.value;
        },
        get frequentQuestions(): IFrequentQuestionsHelpCenter {
          return current.helpCenter.frequentQuestions.value;
        },
      },
      coupons: {
        get isLoad() {
          return current.coupons.isLoad.value;
        },
        get code(): string {
          return current.coupons.code.value;
        },
        get coupons(): ICouponsGroup[] {
          return current.coupons.coupons.value;
        },
        get couponUseText(): ICouponUseText {
          return current.coupons.couponUseText.value;
        },
        get legal(): string {
          return current.coupons.legal.value;
        },
      },
      exchangeExperience: {
        get isLoad() {
          return current.exchangeExperience.isLoad.value;
        },
        get exchangesSection() {
          return current.exchangeExperience.exchangesSection.value;
        },
        get typeSection() {
          return current.exchangeExperience.typeSection.value;
        },
      },
    },
    reset: {
      categoryBenefits: () => {
        current.categoryBenefits.set(Default.categoryBenefits);
      },
    },
  };
}
