import styled from 'styled-components';

const Styled = {
  Container: styled.section`
    display: flex;
    flex-direction: column;

    max-height: 85vh;
    padding: 0 0.5rem;
    overflow: hidden;
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      max-height: 75vh;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-height: 75vh;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-height: 85vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      max-height: 75vh;
      overflow: auto;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-height: 75vh;
      overflow: auto;
    }
  `,
};

export default Styled;
