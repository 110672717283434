import { useCallback, useEffect, useRef } from 'react';

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Definitions
import { IReedemCard } from 'domain/model/content';

// Components
import { Title } from 'presentation/components/atoms';
import Carousel from 'presentation/components/molecules/Carousel';
import { Paragraph, Strong } from 'presentation/components/atoms/Text';
import ReedemCard from 'presentation/components/molecules/ReedemCard';

// Styled
import Styled from './ExchangeExperiences.styled';

// Definitions
import { ExchangeExperiencesProps } from './ExchangeExperiences.def';

const ExchangeExperiences = ({
  title = '',
  description = '',
  exchangeCards = [],
  id,
  utmMedium = '',
  onSelect,
  windowSize = 0,
}: ExchangeExperiencesProps) => {
  // State
  const isDesktop = !useIsMobile(['xs', 'sm']);

  const showCarousel = exchangeCards.length > 0;
  const elementsSpace = isDesktop ? 8 : 24;
  const titleStyle = { margin: `0 42px ${elementsSpace}px 50px` };
  const isCenter = useRef(false);

  const centerCards = useCallback(
    (windowSize: number, cards: Array<Object>): void => {
      if (windowSize >= 320 && cards.length < 2) {
        isCenter.current = true;
      } else if (windowSize >= 768 && cards.length < 3) {
        isCenter.current = true;
      } else if (windowSize >= 992 && cards.length < 4) {
        isCenter.current = true;
      } else if (windowSize >= 1360 && cards.length < 5) {
        isCenter.current = true;
      } else if (windowSize >= 1930 && cards.length < 6) {
        isCenter.current = true;
      } else {
        isCenter.current = false;
      }
    },
    [],
  );

  useEffect(() => {
    centerCards(windowSize, exchangeCards);
  }, [windowSize, exchangeCards, centerCards]);

  const getTitleText = () => {
    const highlightTitleFirstWord = isDesktop;

    if (!highlightTitleFirstWord) {
      return title;
    }

    const titleWords = title.split(' ');
    const [firstWord, ...restWords] = titleWords;

    return [
      <Strong color="primary" key="title-highlighted-word">
        {firstWord}{' '}
      </Strong>,
      restWords.join(' '),
    ];
  };

  const handleClick = (item: IReedemCard) => {
    onSelect && onSelect(item);
  };

  return (
    <Styled.ExchangeExperiencesSection id={id}>
      {!!title && (
        <Title level="2" size="large" style={titleStyle}>
          {getTitleText()}
        </Title>
      )}
      {!!description && (
        <Paragraph
          size="large"
          weight="light"
          align="center"
          style={{ marginBottom: '16px' }}>
          {description}
        </Paragraph>
      )}

      {showCarousel && (
        <Styled.CarouselContainer isCenter={isCenter.current}>
          <Carousel
            style={{ position: 'relative' }}
            id={id}
            customNavigation={{
              isNavigation: exchangeCards.length > 4,
              nextEl: `${id}-swiper-button-next`,
              prevEl: `${id}-swiper-button-prev`,
            }}
            swiperOptions={{
              initialSlide: 0,
              slidesPerView: 'auto',
              spaceBetween: 16,
              speed: 300,
              autoplay: false,
              // navigation: isNavigation,
              navigation: {
                nextEl: `.${id}-swiper-button-next`,
                prevEl: `.${id}-swiper-button-prev`,
              },
              breakpoints: {
                // Cuando el ancho de window es >= a la medida seteada
                320: { slidesPerView: 1.3 },
                360: { slidesPerView: 1.45 },
                375: { slidesPerView: 1.5 },
                390: { slidesPerView: 1.6 },
                411: { slidesPerView: 1.7 },
                460: { slidesPerView: 1.9 },
                540: { slidesPerView: 2.2 },
                600: { slidesPerView: 2.45 },
                660: { slidesPerView: 2.6 },
                700: { slidesPerView: 2.9 },
                768: { slidesPerView: 2.7 },
                992: { slidesPerView: 3.6 },
                1280: { slidesPerView: 4 },
                1360: { slidesPerView: 5 },
                1930: { slidesPerView: 6 },
              },
            }}>
            {exchangeCards.map((item, index) => (
              <Styled.WrapperCard
                deviceoptions={{
                  widthSizes: { sm: 224, md: 224, lg: 224 },
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}-${item.name}`}
                data-testid={`${index + 1}-${id}-reedem-card`}>
                <ReedemCard
                  key={item.name}
                  image={item.image}
                  partnerLogo={item.partnerLogo}
                  badges={item.badges}
                  category={item.category}
                  link={item.link}
                  content={{
                    title: item.title,
                    text: item.text,
                    currentPoints: item.currentPoints,
                    points: item.points,
                  }}
                  showOnlyImage={item.showOnlyImage}
                  utmMedium={utmMedium}
                  onClick={() => handleClick(item)}
                />
              </Styled.WrapperCard>
            ))}
          </Carousel>
        </Styled.CarouselContainer>
      )}
    </Styled.ExchangeExperiencesSection>
  );
};

export default ExchangeExperiences;
