import { useState } from 'react';
import { Carousel } from 'presentation/components/molecules';
import CategoryAndBenefitsCard from 'presentation/components/molecules/CategoryAndBenefitsCard/CategoryAndBenefitsCard';

import categoryAndBenefits from 'domain/configuration/categoryAndBenefits';

import Icon from 'presentation/assets/icons/Icon';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import Styled from './CategoryAndBenefitsSection.style';

type Props = {
  title: string;
  description: string;
};

const INDEX_PREMIUM_CATEGORY_CARD = 1;
const INDEX_ELITE_CATEGORY_CARD = 2;
const breakpoints = {
  320: { slidesPerView: 1.1, spaceBetween: 16 },
  375: { slidesPerView: 1.2, spaceBetween: 16 },
  425: { slidesPerView: 1.2, spaceBetween: 16 },
  520: { slidesPerView: 1.4, spaceBetween: 16 },
  768: { slidesPerView: 1.7, spaceBetween: 32 },
  1024: { slidesPerView: 2.6, spaceBetween: 32 },
  1280: { slidesPerView: 3, spaceBetween: 32 },
  1440: { slidesPerView: 3, spaceBetween: 32 },
};
const CategoryAndBenefitsSection = (props: Props) => {
  const { title, description } = props;
  const [indexCardActive, setIndexCardActive] = useState<number>(0);

  const isMobile = useIsMobile();

  return (
    <>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{description}</Styled.Subtitle>
      </Styled.Header>

      <Styled.Content>
        <Carousel
          handleActiveIndexChange={item => setIndexCardActive(item.activeIndex)}
          id="CategoryAndBenefitsCardsCarousel"
          swiperOptions={{ breakpoints, navigation: true }}>
          {categoryAndBenefits &&
            categoryAndBenefits.categoryList.map(card => (
              <CategoryAndBenefitsCard
                key={card.type}
                type={card.type}
                name={card.name}
                icon={card.icon}
                points={card.points}
                glosa={card.glosa}
                benefitsList={card.benefitsList}
                cta={card.cta}
              />
            ))}
        </Carousel>
      </Styled.Content>
      <Styled.Footer>
        {isMobile &&
          (indexCardActive === INDEX_PREMIUM_CATEGORY_CARD ||
            indexCardActive === INDEX_ELITE_CATEGORY_CARD) && (
            <Styled.DiscleimerPremiumCategory>
              *Este beneficio sólo aplica a clientes que alcanzaron la categoría
              por acumulación de CMR Puntos en compras.
            </Styled.DiscleimerPremiumCategory>
          )}
        {!isMobile && (
          <Styled.DiscleimerPremiumCategory>
            *Este beneficio sólo aplica a clientes que alcanzaron la categoría
            por acumulación de CMR Puntos en compras.
          </Styled.DiscleimerPremiumCategory>
        )}
        <Styled.Alert title="Los beneficios de cada categoría son acumulativos.">
          <Icon size="small" name="info" style={{ marginRight: '12px' }} />
          Los beneficios de cada categoría son acumulativos.
        </Styled.Alert>
      </Styled.Footer>
    </>
  );
};

export default CategoryAndBenefitsSection;
