import { useMemo } from 'react';

// Components
import Title from 'presentation/components/atoms/Title';
import Paragraph from 'presentation/components/atoms/Text/Paragraph';

// Styles
import Styled from './FeebackFormBrand.styled';

const FeedbackContent = {
  success: {
    title: 'Solicitud enviada',
    firstParagraph: '¡Tu información fue enviada con éxito!',
    secondParagraph:
      'Dentro de las próximas horas nos pondremos en contacto contigo para seguir el proceso.',
  },
  error: {
    title: 'Error en envío',
    firstParagraph: 'En este momento no es posible enviar tu solicitud',
    secondParagraph: 'Por favor inténtalo más tarde.',
  },
};

type FeebackFormBrandProps = {
  type?: 'success' | 'error';
  handleModalClose: () => void;
};

const FeebackFormBrand = ({
  type = 'success',
  handleModalClose,
}: FeebackFormBrandProps) => {
  const feedbackContent = useMemo(() => FeedbackContent[type], [type]);

  return (
    <Styled.Container>
      <Title level="2" style={{ margin: 0 }}>
        {feedbackContent.title}
      </Title>
      <Styled.Content>
        <Paragraph
          align="left"
          size="large"
          color="green-alt"
          weight="semi-bold">
          {feedbackContent.firstParagraph}
        </Paragraph>
        <Paragraph align="left" size="large" weight="regular">
          {feedbackContent.secondParagraph}
        </Paragraph>
      </Styled.Content>
      <Styled.WrapperButton isFullWidth rounded onClick={handleModalClose}>
        Cerrar
      </Styled.WrapperButton>
    </Styled.Container>
  );
};

export default FeebackFormBrand;
