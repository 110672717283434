import qs from 'query-string';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

// Domain
import getDocumentTypeValue from 'domain/configuration/documentType';
import { DocumentTypeCode } from 'domain/model';

// Services
import Loyalty from 'infrastructure/services/loyalty';

// Hooks
import useDatadogEvents from 'infrastructure/hooks/Datadog';

// Stores
import UseMovementsStore from 'infrastructure/stores/Movements';

// Utils
import Hash from 'infrastructure/utils/crypto/hash';

// Providers
import { useAnalytics } from '../Analytics';

// Definitions
import {
  AuthProviderValue,
  CompleteProfileProps,
  LoggedDef,
  LoginAttr,
  Subscription,
  SubscriptionErrors,
  SubscriptionState,
} from './Auth.defs';

// Constants
const country = process.env.REACT_APP_COUNTRY || '';

// Check logged in localStorage
const isLoggedIn = () => {
  const logged = localStorage.getItem('logged');

  if (logged && logged === 'true') {
    return true;
  }
  return false;
};

const constructHashId = (sha256HashedId: string, documentType?: string) => {
  return `${country.toUpperCase()}_${documentType}_${sha256HashedId}`;
};

// Defaults values
export const Defaults: AuthProviderValue = {
  data: {
    subscription: {
      success: false,
      evaluation: {
        approvedCreditCard: false,
        approvedCurrentAccount: false,
      },
    },
    logged: {
      in: isLoggedIn(),
      data: undefined,
      error: undefined,
    },
  },
  methods: {
    Create: async (subscription: Subscription, tokenCaptcha: string) => {},
    Login: async (attr: LoginAttr, tokenCaptcha: string) => {},
    OauthLogin: async (authId: string) => {},
    Logout: async () => {},
    Refresh: async () => {},
    CompleteProfile: async (dataForm: CompleteProfileProps) => {},
  },
  reset: {
    subscription: () => {},
    resetUpdateProfile: () => {},
  },
  profileUpdate: {
    success: false,
    error: undefined,
  },
};

const Provider = () => {
  // Services
  const { Auth } = Loyalty;

  // Hooks
  const {
    login: AnalyticsLogin,
    accountMenu: AnalyticsAccountMenu,
    subscribe: AnalyticsSubscribe,
  } = useAnalytics();
  const { reset: resetMovements } = UseMovementsStore();
  const history = useHistory();
  const { addContextUser } = useDatadogEvents();

  // States
  const [subscription, setSubscription] = useState<SubscriptionState>(
    Defaults.data.subscription,
  );

  const [profileUpdate, setProfileUpdate] = useState({
    success: false,
    error: undefined,
  });
  const [logged, setLogged] = useState<LoggedDef>(Defaults.data.logged);
  const [rescueDataUser, setRescueDataUser] = useState({ userToken: '' });
  const intervalRef = useRef<any>();

  // Memoized data
  const data = useMemo(
    () => ({ logged, subscription }),
    [logged, subscription],
  );

  // Refresh interval methods
  const interval = {
    clear: () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    },
    create: (expiresIn: number) => {
      if (!expiresIn) return;

      interval.clear();
      intervalRef.current = setInterval(
        () => methods.Refresh(),
        (expiresIn - 60) * 1000,
      );
    },
  };

  const markAnalyticsEvaluation = (props: {
    approvedCreditCard: boolean;
    approvedCurrentAccount: boolean;
  }) => {
    const { approvedCreditCard, approvedCurrentAccount } = props;

    if (approvedCreditCard) {
      AnalyticsSubscribe.op.approvedCreditCard();
    } else if (approvedCurrentAccount) {
      AnalyticsSubscribe.op.approvedCreditCard();
    }

    AnalyticsSubscribe.op.withoutApproval();
  };

  // Methods
  const methods = {
    Create: async (subscription: Subscription, tokenCaptcha: string) => {
      try {
        // Start
        AnalyticsSubscribe.op.submitted();
        // Operation
        const { documentType, documentNumber, phone, email, terms } =
          subscription;
        const subscriptionRequest = {
          documentType: getDocumentTypeValue(documentType as DocumentTypeCode),
          documentNumber: documentNumber.replaceAll('-', ''),
          phone,
          email,
          termsAndConditions: terms,
          token: tokenCaptcha,
        };

        const { data: response } = await Auth.create(subscriptionRequest);

        if (!response.data) return;

        setRescueDataUser({ userToken: response.data.userToken });

        const { approvedCreditCard, approvedCurrentAccount } = response.data;
        const evaluation = { approvedCreditCard, approvedCurrentAccount };

        setSubscription({ success: true, error: undefined, evaluation });

        // End
        markAnalyticsEvaluation(evaluation);
        AnalyticsSubscribe.op.success();
      } catch (error) {
        const { data: errorBody } = (error as any).response || {};
        const { code: internalCode } = errorBody?.data ?? {};

        switch (internalCode) {
          case SubscriptionErrors.IsRegistered:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsRegistered,
              evaluation: errorBody.data.errors[1],
            });
            AnalyticsSubscribe.error.userIsRegister();
            break;
          case SubscriptionErrors.IsBankUserRegistered:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsBankUserRegistered,
            });
            AnalyticsSubscribe.error.userIsBank();
            break;
          case SubscriptionErrors.IsPhoneExisting:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsPhoneExisting,
            });
            AnalyticsSubscribe.error.phoneIsRegister();
            break;
          case SubscriptionErrors.IsEmailExisting:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsEmailExisting,
            });
            AnalyticsSubscribe.error.emailIsRegister();
            break;
          case SubscriptionErrors.IsDocumentNumberInvalid:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsDocumentNumberInvalid,
            });
            AnalyticsSubscribe.error.invalidDocument();
            break;
          case SubscriptionErrors.IsPhoneInvalid:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsPhoneInvalid,
            });
            AnalyticsSubscribe.error.invalidPhone();
            break;
          case SubscriptionErrors.IsEmailInvalid:
            setSubscription({
              success: false,
              error: SubscriptionErrors.IsEmailInvalid,
            });
            AnalyticsSubscribe.error.invalidEmail();
            break;
          default:
            setSubscription({
              success: false,
              error: SubscriptionErrors.ServiceError,
            });
            AnalyticsSubscribe.error.unexpected();
            break;
        }
      }
    },
    Login: async (attr: LoginAttr, tokenCaptcha: string) => {
      try {
        const documentNumber = attr.dni.replaceAll('-', '').toUpperCase();
        const { isSso, password, type, dni } = attr;
        const loginRequest = {
          documentNumber: dni.replaceAll('-', '').toUpperCase(),
          documentType: getDocumentTypeValue(type as DocumentTypeCode),
          password,
          token: tokenCaptcha,
        };
        const options = { isSso };
        // Generate HashId
        const sha256HashedId = Hash.sha256(documentNumber);

        // Initialize Datadog Context
        addContextUser(constructHashId(sha256HashedId, attr.type || 'RUT'));

        // Start AnalyticsLogin
        AnalyticsLogin.op.submitted();

        // Operation
        const { data: response } = await Auth.login(loginRequest, options);

        // Success AnalyticsLogin
        AnalyticsLogin.op.success();

        if (isSso) {
          setLogged({
            ssoSuccess: true,
            data: response.data,
            error: undefined,
          });
        } else {
          setLogged({ in: true, data: response.data, error: undefined });
          localStorage.setItem('logged', 'true');
          interval.create(response.data.expiresIn);

          const redirectTo = localStorage.getItem('redirectTo');

          if (!redirectTo) {
            setTimeout(() => history.push('/mi-cuenta'), 200);
          }

          if (redirectTo) {
            setTimeout(() => history.push(`${redirectTo}`), 700);
            localStorage.removeItem('redirectTo');
          }
        }
      } catch (details: any) {
        console.error(details);
        const error =
          details.request.status === 0 ? 500 : details.request.status;
        setLogged({ ...Defaults.data.logged, in: false, error });
        localStorage.removeItem('logged');
        interval.clear();

        // Analytics fail
        if (error === 409) AnalyticsLogin.error.invalid();
        else if (error === 423) AnalyticsLogin.error.blocked();
        else AnalyticsLogin.error.unexpected();
      }
    },
    OauthLogin: async (authId: string) => {
      try {
        // Start
        AnalyticsLogin.op.submitted({ to: 'Canal Banco' });

        // Operation
        const { data: response } = await Auth.oauth(authId);
        setLogged({ in: true, data: response.data, error: undefined });
        localStorage.setItem('logged', 'true');
        interval.create(response.data.expiresIn);

        // End
        AnalyticsLogin.op.success({ to: 'Canal Banco' });
      } catch (details: any) {
        console.error(details);
        const error =
          details.request.status === 0 ? 500 : details.request.status;
        setLogged({ ...Defaults.data.logged, in: false, error });
        localStorage.removeItem('logged');
        localStorage.removeItem('redirectTo');
        interval.clear();

        // Analytics fail
        if (error === 409) AnalyticsLogin.error.invalid();
        else if (error === 423) AnalyticsLogin.error.blocked();
        else AnalyticsLogin.error.unexpected();
      }
    },
    Logout: async () => {
      try {
        await Auth.logout();
        const resetState = { ...Defaults.data.logged, in: false };
        history.push('/');
        setLogged(resetState);
        localStorage.removeItem('logged');
        localStorage.removeItem('redirectTo');
        // Analytics
        AnalyticsAccountMenu.op.logout();
      } catch (details: any) {
        const error =
          details.request.status === 0 ? 500 : details.request.status;
        setLogged({ ...Defaults.data.logged, in: false, error });
      }
      localStorage.removeItem('logged');
      localStorage.removeItem('redirectTo');
      resetMovements();
    },
    Refresh: async () => {
      try {
        const { data: response } = await Auth.refresh();
        setLogged({ in: true, data: response.data, error: undefined });
        localStorage.setItem('logged', 'true');
        interval.create(response.data.expiresIn);
      } catch (details: any) {
        console.error(details);
        const error =
          details.request.status === 0 ? 500 : details.request.status;
        setLogged({ ...Defaults.data.logged, in: false, error });
        localStorage.removeItem('logged');
        localStorage.removeItem('redirectTo');
        interval.clear();
        resetMovements();
      }
    },
    CompleteProfile: async (dataForm: CompleteProfileProps) => {
      const updateProfile = { ...rescueDataUser, ...dataForm };

      try {
        // Start
        AnalyticsSubscribe.op.submittedProfile();
        const { data: response } = await Auth.completeProfile(updateProfile);

        if (response) {
          setProfileUpdate({ ...profileUpdate, success: true });
          setRescueDataUser({ userToken: '' });
          AnalyticsSubscribe.op.successUpdateProfile();
        }
      } catch (error: any) {
        setProfileUpdate({ success: false, error });
        AnalyticsSubscribe.error.invalidCompleteProfile();
      }
    },
  };

  // Resets handlers
  const reset = {
    subscription: () => setSubscription({ success: false, error: undefined }),
    resetUpdateProfile: () =>
      setProfileUpdate({ success: false, error: undefined }),
  };

  // On load page
  useEffect(() => {
    // TODO: Move to utils
    const { authID } = qs.parse(window.location.search);

    if (authID) {
      methods.OauthLogin(authID as string);
      return;
    }

    if (isLoggedIn()) {
      methods.Refresh();
    }
  }, []);

  return { data, methods, reset, profileUpdate };
};

const Context = createContext(Defaults);

export type ProviderProps = {
  children: ReactNode;
  mock?: AuthProviderValue;
};

/**
 * Component provider
 * @param children
 * @param mock
 * @return {*}
 * @constructor
 */
const AuthProvider = ({ children, mock }: ProviderProps) => {
  const value = mock ?? Provider();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
export const useAuth = () => useContext(Context);

export default AuthProvider;
