import { domainConfig, domainValidation, excludeDomainEmail } from '../shared';

const domainExclude = [
  '@yopmail',
  '@moakt',
  '@jmalaysiaqc.com',
  '@maildrop',
  '@guerrillamail',
  '@firemailbox',
  '@mailsire',
  '@hideaddress',
  '@hash',
];

const formatEmail = (email: string): boolean => {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
};

const emailValidation = (email: string) => {
  const response = { valid: true, message: '' };
  const splitEmail = email?.split('@');
  const domainEmail = splitEmail[1].split('.')[0]?.toLowerCase();
  const topLevelDomain = splitEmail[1].split('.')[1]?.toLowerCase();
  const errorMessage = 'Formato de correo no válido';

  if (!formatEmail(email)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!excludeDomainEmail(email, domainExclude)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // gmail
  if (!domainValidation(domainEmail, domainConfig.gmail)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // yahoo
  if (!domainValidation(domainEmail, domainConfig.yahoo)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // yahoo
  if (!domainValidation(domainEmail, domainConfig.outlook)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // hotmail
  if (!domainValidation(domainEmail, domainConfig.hotmail)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // .con invalid
  if (topLevelDomain === 'con') {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  return response;
};

export default emailValidation;
