import {
  domainConfig,
  domainValidation,
  excludeDomainEmail,
  excludeWords,
} from '../shared';

const domainExclude = [
  '@yopmail',
  '@moakt',
  '@jmalaysiaqc.com',
  '@maildrop',
  '@guerrillamail',
  '@firemailbox',
  '@mailsire',
  '@hideaddress',
  '@hash',
  '@notengomail',
  '@sinmail',
  '@notengocorreo',
  '@sincorreo',
  '@micorreo',
  '@notengo',
  '@notiene',
  '@n0tienec0rreo',
];

const wordsExclude = [
  'notengomail',
  'sinmail',
  'notengocorreo',
  'sincorreo',
  'micorreo',
  'notengo',
  'notiene',
  'n0tienec0rreo',
];

const formatEmail = (email: string): boolean => {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
};

const consonants = (email: string): boolean => {
  const prefixEmail = email?.split('@')[0]?.toLowerCase();

  return (
    !prefixEmail.includes('a') &&
    !prefixEmail.includes('e') &&
    !prefixEmail.includes('i') &&
    !prefixEmail.includes('o') &&
    !prefixEmail.includes('u')
  );
};

const emailValidation = (email: string) => {
  const response = { valid: true, message: '' };
  const splitEmail = email?.split('@');
  const domainEmail = splitEmail[1].split('.')[0]?.toLowerCase();
  const errorMessage = 'Formato de correo no válido';

  if (!formatEmail(email)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (consonants(email)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!excludeWords(email, wordsExclude)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  if (!excludeDomainEmail(email, domainExclude)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // gmail
  if (!domainValidation(domainEmail, domainConfig.gmail)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // yahoo
  if (!domainValidation(domainEmail, domainConfig.yahoo)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // yahoo
  if (!domainValidation(domainEmail, domainConfig.outlook)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  // hotmail
  if (!domainValidation(domainEmail, domainConfig.hotmail)) {
    response.message = errorMessage;
    response.valid = false;
    return response;
  }

  return response;
};

export default emailValidation;
