import { css } from 'styled-components';

export const ResetMixin = css`
  &,
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const BoxShadowMixin = css`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
`;

export const RoundBorderMixin = css`
  border-radius: 6px;
`;

export const TextDefaultFontMixin = css`
  font-family: 'pfbeausans';
`;

export const ImageClipMixin = (clipSvg: string) => css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background-image: url(${clipSvg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

export type TextSize = 'x-small' | 'small' | 'regular' | 'large' | 'x-large';

export const TextSizeMixin = (size: TextSize) => css`
  ${size === 'x-small' &&
  `
    font-size: 13px;
    line-height: 175%;
    letter-spacing: -0.01em;
  `};

  ${size === 'small' &&
  `
    font-size: 14px;
    line-height: 175%;
    letter-spacing: -0.02em;
  `};

  ${size === 'regular' &&
  `
    font-size: 15px;
    line-height: 175%;
    letter-spacing: -0.02em;
  `};

  ${size === 'large' &&
  `
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
  `};
  ${size === 'x-large' &&
  `
    font-size: 18px;
    line-height: 133%;
    letter-spacing: -0.01em;
  `};
  ${size === 'x-large' &&
  `
    font-size: 20px;
    line-height: 200%;
    letter-spacing: -0.01em;
  `};
`;

export type TextWeight = 'x-light' | 'light' | 'regular' | 'semi-bold' | 'bold';

export const TextWeightMixin = (weight: TextWeight) => css`
  ${weight === 'x-light' && 'font-weight: 200;'}
  ${weight === 'light' && 'font-weight: 300;'}
  ${weight === 'regular' && 'font-weight: 400;'}
  ${weight === 'semi-bold' && 'font-weight: 600;'}
  ${weight === 'bold' && 'font-weight: 700;'}
`;

export const PointerMixin = css`
  cursor: pointer;

  // Chrome mobile Android blue flashing fix
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
`;
