import { lazy, useEffect, useLayoutEffect } from 'react';

// Infrastructure
import { useConfiguration } from 'infrastructure/providers/Configuration';
import { useContentful } from 'infrastructure/providers/Contentful';
import { useAnalytics } from 'infrastructure/providers/Analytics';
import { useAuth } from 'infrastructure/providers/Auth';
import useIsMobile from 'infrastructure/hooks/IsMobile';
import useWalkthrough from 'infrastructure/hooks/Walkthrough';

// Components
import LegalInformation from 'presentation/components/organisms/LegalInformation';
import Exchanges from 'presentation/components/organisms/Exchanges';
import Redeem from 'presentation/components/organisms/Redeem';
import LoginIncentive from 'presentation/components/molecules/LoginIncentive';
import TextImageBanner from 'presentation/components/organisms/TextImageBanner';
import CategoryAndBenefitsSection from 'presentation/components/organisms/CategoryAndBenefitsSection/CategoryAndBenefitsSection';
import AlliesSection from 'presentation/components/molecules/AlliesSection/AlliesSection';
import AcumulateMorePoints from 'presentation/components/organisms/AcumulateMorePoints/AcumulateMorePoints';
import EcosystemSection from 'presentation/components/molecules/EcosystemSection/EcosystemSection';
import BrandSection from 'presentation/components/molecules/BrandSection/BrandSection';
import MainSlider from 'presentation/components/organisms/MainSlider/MainSlider';

// Utils
import setPageTitle from 'presentation/utils/title';

// Styled Components
import Styled from './Home.styled';

// Lazy component
const Footer = lazy(() => import('presentation/components/organisms/Footer'));
const Gifcards = lazy(
  () => import('presentation/components/organisms/Giftcards'),
);

const Home = () => {
  // Hooks
  const {
    configuration: { features },
  } = useConfiguration();

  const {
    data: { home, common, mainSlider },
    methods: { LoadHome },
  } = useContentful();

  const {
    data: { logged },
  } = useAuth();

  const { openWalkthrough } = useWalkthrough();

  const isMobile = useIsMobile();

  // Analytics
  const analytics = useAnalytics();

  const commonUI = features.ui.common;
  const homeUI = features.ui.home;

  // On DOM Render
  useLayoutEffect(() => {
    setPageTitle(
      'Inscríbete, acumula pagando como quieras y canjea miles de productos',
    );
  }, []);

  // On load
  useEffect(() => {
    LoadHome();
    analytics.common.setPageLoad(window.location.pathname);
  }, [LoadHome, analytics.common, common]);

  // trigger walkthrough
  useEffect(() => {
    openWalkthrough();
  }, []);

  return (
    <>
      {mainSlider.isLoad && homeUI.slider && !logged.in && (
        <Styled.WrapperSlider>
          <MainSlider id="fif-slider" content={mainSlider.content} />
        </Styled.WrapperSlider>
      )}

      {home.isLoad && (
        <Exchanges
          title={home.newExchangesSection.name}
          cards={home.newExchangesSection.cards}
          description={home.newExchangesSection.description}
        />
      )}
      {!logged.in && common.loginIncentive?.text && (
        <LoginIncentive allowClose data={common.loginIncentive} />
      )}
      {home.isLoad && (
        <Redeem data={{ redeem: home.outstandingExchangesTab }} />
      )}
      {home.isLoad && (
        <CategoryAndBenefitsSection
          title={home.categoryAndBenefits?.title}
          description={home.categoryAndBenefits?.description}
        />
      )}
      {home.isLoad && home.acumulationMorePointsSection.visible && (
        <AcumulateMorePoints data={home.acumulationMorePointsSection.cards} />
      )}
      {homeUI.giftcard && home.isLoad && (
        <Gifcards
          notificationData={home.giftcardNotification}
          onClick={() => {
            const route = process.env
              .REACT_APP_DEEPLINK_BANCO_APP_GIFTCARD as string;
            window.open(route, '_self', 'noopener noreferrer');
          }}
        />
      )}
      {home.isLoad && home.sectionAcumulatesBanner && (
        <TextImageBanner
          data={home.sectionAcumulatesBanner}
          id="home-acumulated-banner"
          style={{ margin: '0 0 80px 0', paddingBottom: '0' }}
          alignText="left"
        />
      )}

      {home.isLoad && home.alliesSection && home.alliesSection.visible && (
        <AlliesSection data={home.alliesSection} />
      )}

      {home.isLoad && home.brandSection && home.brandSection.visible && (
        <BrandSection data={home.brandSection} />
      )}

      {home.isLoad &&
        home.ecosystemSection &&
        home.ecosystemSection.visible && (
          <EcosystemSection
            data={home.ecosystemSection}
            banner={home.ecosystemBanner}
          />
        )}

      {commonUI.legalInformation && (
        <LegalInformation
          content={common.interestLinksContent}
          style={!isMobile ? { padding: '40px 80px 0' } : undefined}
        />
      )}
      {commonUI.footer && (
        <Footer
          content={common.interestLinksContent}
          copyright={common.copyRightContent.text}
          companyData={common.companyDataContent}
        />
      )}
    </>
  );
};

export default Home;
