import cellValidation from 'infrastructure/utils/validations/cellPhone/pe';
import emailValidation from 'infrastructure/utils/validations/email/pe';
import Formatters from '../../../formatters';

import {
  FormButton,
  FormDef,
  FormError,
  FormFeedback,
  FormInput,
  FormText,
  Mode,
  Type,
} from '../Form.defs';

// Formatters
const { number: numberFormatter } = Formatters;

const Form: FormDef = {
  id: 'registry-form',
  title: { text: 'Únete a CMR Puntos' } as FormText,
  subtitle: { text: 'Y acumula pagando como quieras' } as FormText,
  titleCompleteProfile: { text: 'Completa tu Perfil' } as FormText,
  subtitleCompleteProfile: {
    text: 'Y disfruta de todos tus beneficios',
  } as FormText,
  components: [
    {
      id: 'enterpriseName',
      inputId: 'enterpriseName',
      name: 'enterpriseName',
      placeholder: 'Nombre empresa',
      type: Type.text,
      mode: Mode.text,
      maxLength: { value: 20, message: 'Nombre empresa no válido' },
      minLength: { value: 3, message: 'Nombre empresa no válido' },
    } as FormInput,
    {
      id: 'contactName',
      inputId: 'contactName',
      name: 'contactName',
      placeholder: 'Nombre contacto',
      type: Type.text,
      mode: Mode.text,
      maxLength: { value: 20, message: 'Nombre contacto no válido' },
      minLength: { value: 3, message: 'Nombre contacto no válido' },
    } as FormInput,
    {
      id: 'contactPhone',
      name: 'contactPhone',
      placeholder: 'Celular de 9 dígitos',
      type: Type.tel,
      mode: Mode.numeric,
      maxLength: { value: 9, message: 'Número de celular no válido' },
      minLength: { value: 9, message: 'Número de celular no válido' },
      addonText: '+51',
      help: 'Necesario para mantenerte informado sobre tu solicitud',
      formatter: numberFormatter,
      validator: cellValidation,
    } as FormInput,
    {
      id: 'enterpriseEmail',
      name: 'enterpriseEmail',
      placeholder: 'Correo electrónico',
      type: Type.email,
      mode: Mode.email,
      help: 'Te mantendremos informados sobre tus CMR Puntos',
      validator: emailValidation,
    } as FormInput,
    {
      id: 'submit',
      name: 'submit',
      text: 'Quiero inscribirme',
    } as FormButton,
  ],
  feedback: [] as FormFeedback[],
  errors: [
    { code: '01', description: 'Número de celular no válido' },
    { code: '02', description: 'Formato de correo no válido' },
    { code: '03', description: 'Debe ingresar un nombre de Empresa' },
    { code: '04', description: 'Debe ingresar un Contacto' },
  ] as FormError[],
};

export default Form;
