import * as React from 'react';

// Domain configuration
import { FormText } from 'domain/configuration/form';

// Components
import { Strong } from 'presentation/components/atoms/Text';
import SmartLink from 'presentation/components/organisms/SmartLink';

// Definitions
type TextRendererOptions = { strongColor?: string };

export type Props = {
  value: string | FormText | FormText[];
  options?: TextRendererOptions;

  /* this event is only implemented for smartlink element */
  onClick?: (id: string | undefined) => void;
};

const TextRenderer = (props: Props) => {
  const { value, options = {}, onClick = () => {} } = props;
  const { strongColor = 'dark' } = options;

  if (!value) return null;

  if (typeof value === 'string') return <>{value}</>;

  if (Array.isArray(value))
    return (
      <>
        {value.map(item => (
          <TextRenderer
            onClick={onClick}
            value={item}
            options={options}
            key={item.id}
          />
        ))}
      </>
    );

  if (value.strong) return <Strong color={strongColor}>{value.text}</Strong>;

  if (value.url)
    return (
      <SmartLink
        id={value.id}
        target={value.target}
        url={value.url}
        mode="inline"
        onClick={() => onClick(value.id)}>
        {value.text}
      </SmartLink>
    );

  return (
    <TextRenderer onClick={onClick} value={value.text} options={options} />
  );
};

export default TextRenderer;
