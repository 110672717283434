export type exchangeName =
  | 'productos'
  | 'experiencias'
  | 'giftcards'
  | 'viajes';

export enum maxCenteredCards {
  mobile = 2,
  tablet = 4,
  desktop = 5,
  wideDesktop = 6,
}

export type CardStyledProps = {
  exchangeActive?: boolean;
  exchangeName?: exchangeName;
};

export type ContainerProps = {
  numberOfCards: number;
};
