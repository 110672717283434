import styled from 'styled-components';
import { ITextImage } from 'domain/model/content';
import { InfoProps } from './TextImageBanner.def';

// Styles
export const Hero = styled.section<ITextImage>`
  width: 100%;
  padding: 0 0 30px 0;
  margin: 20px 0;
  background-color: #${props => props.bgColor || 'fff'};
  @media ${({ theme }) => theme.devices.desktop} {
    padding: 30px 0;
  }
`;

export const Container = styled.div<ITextImage>`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
  @media ${({ theme }) => theme.devices.desktop} {
    flex-direction: ${props =>
      props.itemsAlign === 'right' ? 'row-reverse' : 'row'};
    > * {
      width: ${props => (props.haveImage ? '50%' : '100%')};
    }
  }
`;

export const InfoWrapper = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  text-align: ${({ alignText }) => alignText || 'center'};
  align-items: ${({ alignText }) => {
    switch (alignText) {
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      default:
        return 'center';
    }
  }};
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 32px;
    }
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  @media ${({ theme }) => theme.devices.desktop} {
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
  }
  p {
    margin-bottom: 10px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
`;
