import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

// Domain models
import {
  ICustomer,
  CustomerType,
  CustomerCategoryCode,
  IPointsExpiration,
  CustomerCategoryName,
  ICustomerUpdate,
  IReturnCustomerUpdate,
  IReturnCustomerData,
  INextCategoryAcumulation,
  IBenefits,
} from 'domain/model';

// Services
import Services from 'infrastructure/services/loyalty';

// Providers
import { useAuth } from '../Auth';

// Definitions
import {
  CustomerErrors,
  CustomerProviderValue,
  CustomerStatusHttpErrors,
} from './Customer.defs';

// Defaults values
export const Default: CustomerProviderValue = {
  data: {
    loading: true,
    error: undefined,
    customer: {
      firstName: '',
      lastName: '',
      type: CustomerType.NoType,
      points: {
        total: NaN,
        expiration: [],
        earned: NaN,
      },
      nextCategory: {
        id: CustomerCategoryCode.Fan,
        name: CustomerCategoryName.Fan,
        requiredPoints: 0,
        requiredPointsToUpgrade: 0,
      },
      category: {
        currentCategory: {
          id: CustomerCategoryCode.Normal,
          name: CustomerCategoryName.Normal,
          origin: 'Transactions',
        },
        productCategory: {
          id: CustomerCategoryCode.Normal,
          name: CustomerCategoryName.Normal,
          startDate: undefined,
          endAlertBenefitDate: undefined,
        },
        accumulationCategory: {
          id: CustomerCategoryCode.Normal,
          name: CustomerCategoryName.Normal,
          startDate: undefined,
          endDate: undefined,
          requiredPoints: 0,
          requiredPointsToMaintain: 0,
          endAlertBenefitDate: undefined,
          startAlertKeepCatDate: undefined,
          nextCategory1: {
            id: CustomerCategoryCode.Fan,
            name: CustomerCategoryName.Fan,
            requiredPoints: 0,
            requiredPointsToUpgrade: 0,
          },
        },
      },
      benefits: [],
    },
  },
  methods: {
    getType: async () => undefined,
    reset: () => {},
    getExpirationPoints: () => [],
    customerUpdate: async () => undefined,
    getCustomerData: async () => undefined,
  },
};

const Provider = () => {
  // Services
  const { Customer } = Services;

  // Providers
  const {
    data: { logged },
  } = useAuth();

  // States
  const [loading, setLoading] = useState<boolean>(Default.data.loading);
  const [customer, setCustomer] = useState<ICustomer>(Default.data.customer);
  const [error, setError] = useState<CustomerErrors | undefined>(
    Default.data.error,
  );

  // Memoized data
  const data = useMemo(
    () => ({ loading, customer, error }),
    [loading, customer, error],
  );

  const mapBenefits = (benefits: any): IBenefits[] => {
    return benefits.map((item: any) => {
      return {
        id: item.id,
        resourceName: item.resource_name,
        reedeemCode: item.reedeem_code,
        origin: item.origin,
        description: item.description,
        isRedeemed: item.is_redeemed,
        createdAt: item.created_at,
        expirationDate: item.expiration_date,
      };
    });
  };

  const load = async () => {
    if (!logged.data) {
      return;
    }

    setLoading(true);

    try {
      const { data: response } = await Customer.me(logged.data.accessToken);

      if (!response.data) return;
      const { firstName, lastName, type, category, points, benefits } =
        response.data;
      const { nextCategory1 } = category.accumulationCategory;
      const { totalPoints, pointsToExpire, earned } = points;

      const total = Number.isInteger(totalPoints) ? totalPoints : NaN;
      const expiration = pointsToExpire || [];

      setCustomer({
        firstName,
        lastName,
        category,
        nextCategory: nextCategory1 as INextCategoryAcumulation,
        type: type === 'Banco' ? CustomerType.Bank : CustomerType.Loyalty,
        points: {
          total,
          expiration,
          earned,
        },
        benefits: benefits ? mapBenefits(benefits) : [],
      });
    } catch (details: any) {
      setError(details.request.status);
    }

    setLoading(false);
  };

  const clearError = () => setError(undefined);

  // Loads customer when gets logged
  useEffect(() => {
    async function loadCustomer() {
      await load();
    }

    if (logged.in) {
      loadCustomer();
    }

    if (!logged.in) {
      setError(undefined);
    }
  }, [logged]);

  // Methods
  const getDefaultExpirationMonths = (countElementReturn: number = 3) => {
    const now = new Date(Date.now());
    const currentMonth = now.getMonth();

    return Array.from(Array(countElementReturn).keys()).map(item => {
      now.setMonth(currentMonth + item, 1);
      return now.toLocaleString('es-es', { month: 'short' });
    });
  };

  const methods = {
    getType: async (
      documentType: string,
      documentNumber: string,
      tokenCaptcha: string,
      phone?: string,
    ): Promise<CustomerType | undefined> => {
      try {
        clearError();
        await Customer.customerType({
          documentType,
          documentNumber,
          tokenCaptcha,
          phone,
        });
        return CustomerType.Loyalty;
      } catch (error: any) {
        const { status: statusCode } = error.request;
        if (statusCode === CustomerStatusHttpErrors.NotLoyaltyCustomerError) {
          setError('NotLoyaltyCustomerError');
          return CustomerType.Bank;
        }
        if (statusCode === CustomerStatusHttpErrors.InvalidData) {
          setError('InvalidData');
          return CustomerType.NoValidate;
        }
        setError('ServiceError');
      }
    },
    reset: () => {
      setCustomer(Default.data.customer);
    },
    getExpirationPoints: (): IPointsExpiration[] => {
      const points = data.customer.points.expiration;
      const displayDefaultMonthsExpiration = !points || points.length === 0;

      if (displayDefaultMonthsExpiration) {
        return getDefaultExpirationMonths().map(monthName => ({
          month: monthName,
          shortMonth: monthName,
          points: NaN,
        }));
      }

      return points;
    },
    customerUpdate: async (
      params: ICustomerUpdate,
      token: string | undefined,
    ): Promise<IReturnCustomerUpdate | undefined> => {
      const { data } = await Customer.customerUpdate(params, token);
      return data;
    },
    getCustomerData: async (
      token: string | undefined,
    ): Promise<IReturnCustomerData | undefined> => {
      try {
        const { data } = await Customer.customerData(token);
        return data;
      } catch {
        setError('ServiceError');
      }
    },
  };

  return { data, methods };
};

const Context = createContext(Default);

/**
 * Component provider
 * @param children
 * @return {*}
 * @constructor
 */
const CustomerProvider = ({ children }: any): any => {
  const value = Provider();

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
export const useCustomer = () => useContext(Context);

export default CustomerProvider;
