import * as React from 'react';
import { CSSProperties } from 'react';

import { StyledExtendable } from 'presentation/utils/styles';

// Assets
import inscribeteAcumula, {
  ReactComponent as InscribeteAcumula,
} from 'presentation/assets/icons/inscribeteAcumula.svg';
import inscribeteCanjea, {
  ReactComponent as InscribeteCanjea,
} from 'presentation/assets/icons/inscribeteCanjea.svg';
import inscribeteCategorias, {
  ReactComponent as InscribeteCategorias,
} from 'presentation/assets/icons/inscribeteCategorias.svg';
import logoutLeft, { ReactComponent as LogoutLeft } from './logoutLeft.svg';
import back, { ReactComponent as Back } from './back.svg';
import bags, { ReactComponent as Bags } from './bags.svg';
import next, { ReactComponent as Next } from './next.svg';
import nextLink, { ReactComponent as NextLink } from './ico-link.svg';
import cmr, { ReactComponent as Cmr } from './cmrPuntos.svg';
import cmrSmall, { ReactComponent as CmrSmall } from './cmrPuntosSmall.svg';
import close, { ReactComponent as Close } from './close.svg';
import info, { ReactComponent as Info } from './info.svg';
import home, { ReactComponent as Home } from './icon-home.svg';
import enter, { ReactComponent as Enter } from './icon-enter.svg';
import signup, { ReactComponent as Signup } from './icon-signup.svg';
import requests, { ReactComponent as Requests } from './icon-requests.svg';
import benefits, { ReactComponent as Benefits } from './icon-benefits.svg';
import faq, { ReactComponent as Faq } from './icon-faq.svg';
import settings, { ReactComponent as Settings } from './icon-settings.svg';
import chevronDown, {
  ReactComponent as ChevronDown,
} from './icon-chevron-down.svg';
import success, { ReactComponent as Success } from './success.svg';
import warning, { ReactComponent as Warning } from './warning.svg';
import alert, { ReactComponent as Alert } from './alert.svg';
import facebook, { ReactComponent as Facebook } from './facebook.svg';
import instagram, { ReactComponent as Instagram } from './instagram.svg';
import youtube, { ReactComponent as Youtube } from './youtube.svg';
import falabellaBankLarge, {
  ReactComponent as FalabellaBankLarge,
} from './falabellaBankLarge.svg';
import bank, { ReactComponent as Bank } from './bancoFalabellaLogo.svg';
import bankCmr, { ReactComponent as BankCmr } from './BancoFalabellaCmr.svg';
import bankCmrColor, {
  ReactComponent as BankCmrColor,
} from './bancoFalabellaWithCmr.svg';
import bankVertical, {
  ReactComponent as BankVertical,
} from './logo-bf-vertical.svg';
import bankWhite, { ReactComponent as BankWhite } from './logo-BF-white.svg';
import mobileDevice, {
  ReactComponent as MobileDevice,
} from './mobileDevice.svg';
import fan, { ReactComponent as Fan } from './fan.svg';
import elite, { ReactComponent as Elite } from './elite.svg';
import premium, { ReactComponent as Premium } from './premium.svg';
import offers, { ReactComponent as Offers } from './offers.svg';
import lock, { ReactComponent as Lock } from './lock.svg';
import unLock, { ReactComponent as UnLock } from './icn-unlock.svg';
import statement, { ReactComponent as Statement } from './statement.svg';
import arrowLeft, { ReactComponent as ArrowLeft } from './arrow-left.svg';
import arrowRight, { ReactComponent as ArrowRight } from './arrow-right.svg';
import question, { ReactComponent as Question } from './question.svg';
import search, { ReactComponent as Search } from './search.svg';
import plus, { ReactComponent as Plus } from './plus.svg';
import minus, { ReactComponent as Minus } from './minus.svg';
import questionWhite, {
  ReactComponent as QuestionWhite,
} from './question-white.svg';
import arrowLeftWhite, {
  ReactComponent as ArrowLeftWhite,
} from './arrow-left-white.svg';
import cmrPuntosGray, {
  ReactComponent as CMRPuntosGray,
} from './cmrPuntosGray.svg';
import cmrPuntoGray, {
  ReactComponent as CMRPuntoGray,
} from './cmrPuntoGray.svg';
import nextPageActive, {
  ReactComponent as NextPageActive,
} from './nextPageActive.svg';
import nextPageDisabled, {
  ReactComponent as NextPageDisabled,
} from './nextPageDisabled.svg';
import prevPageActive, {
  ReactComponent as PrevPageActive,
} from './prevPageActive.svg';
import prevPageDisabled, {
  ReactComponent as PrevPageDisabled,
} from './prevPageDisabled.svg';
import squareClose, { ReactComponent as SquareClose } from './squareClose.svg';
import email, { ReactComponent as Email } from './email.svg';
import phone, { ReactComponent as Phone } from './phone.svg';
import triangleWarning, {
  ReactComponent as TriangleWarning,
} from './triangleWarning.svg';
import falabellaBankRectangle, {
  ReactComponent as FalabellaBankRectangle,
} from './falabellaBankRectangle.svg';
import calendar, { ReactComponent as Calendar } from './calendar.svg';
import paperClip, { ReactComponent as PaperClip } from './paperClip.svg';
import trash, { ReactComponent as Trash } from './trash.svg';
import fourOhFour, { ReactComponent as FourOhFour } from './fourOhFour.svg';
import arrowSliderRightWhite, {
  ReactComponent as ArrowSliderRightWhite,
} from './icon-arrow-right-white.svg';
import arrowSliderLeftWhite, {
  ReactComponent as ArrowSliderLeftWhite,
} from './icon-arrow-left-white.svg';
import offline, { ReactComponent as Offline } from './icon-sin-conexion.svg';
import noCmrPoints, {
  ReactComponent as NoCmrPoints,
} from './icon-no-cmr-points.svg';
import fpay, { ReactComponent as Fpay } from './fpay.svg';
import otrosMediosPago, {
  ReactComponent as OtrosMediosPago,
} from './otros-medios-pago.svg';
import tarjetaCMRBF, {
  ReactComponent as TarjetaCMRBF,
} from './tarjeta-CMRBF.svg';
import tarjetaDebitoBF, {
  ReactComponent as TarjetaDebitoBF,
} from './tarjeta-debitoBF.svg';
import buyNowPayLater, {
  ReactComponent as BuyNowPayLater,
} from './buy-now-pay-later.svg';
import fpayLogoApp, {
  ReactComponent as FpayLogoApp,
} from './fpay-logo-app.svg';
import botonGooglePlay, {
  ReactComponent as BotonGooglePlay,
} from './boton-google-play.svg';
import botonAppStore, {
  ReactComponent as BotonAppStore,
} from './boton-app-store.svg';
import falabellaGroup, {
  ReactComponent as FalabellaGroup,
} from './grupo-falabella.svg';
import falabellaCMRGroup, {
  ReactComponent as FalabellaCMRGroup,
} from './grupo-falabella-cmr.svg';
import suggestions, {
  ReactComponent as Suggestions,
} from './icn-suggestions.svg';
import falabellaGroupLogin, {
  ReactComponent as FalabellaGroupLogin,
} from './icn-falabella-group-login.svg';
import falabellaGroupLoginReg, {
  ReactComponent as FalabellaGroupLoginReg,
} from './icn-falabella-group-login-reg.svg';
import cmrPuntosCircle, {
  ReactComponent as CmrPuntosCircle,
} from './cmr-puntos-circle.svg';
import eliteLine, { ReactComponent as EliteLine } from './elite-line.svg';
import eliteOnly, { ReactComponent as EliteOnly } from './elite-only.svg';
import fanLine, { ReactComponent as FanLine } from './fan-line.svg';
import fanOnly, { ReactComponent as FanOnly } from './fan-only.svg';
import premiumLine, { ReactComponent as PremiumLine } from './premium-line.svg';
import premiumOnly, { ReactComponent as PremiumOnly } from './premium-only.svg';
import loadFailed, { ReactComponent as LoadFailed } from './load-failed.svg';
import myExchanges, { ReactComponent as MyExchanges } from './myExchanges.svg';
import profile, { ReactComponent as Profile } from './profile.svg';
import download, { ReactComponent as Download } from './icn-download.svg';
import arrowNext, { ReactComponent as ArrowNext } from './arrow-next.svg';
import logoCmrpuntos, {
  ReactComponent as LogoCmrpuntos,
} from './icon-logo-cmrpuntos.svg';
import cmrCredit, { ReactComponent as CmrCredit } from './icon-cmr-credit.svg';
import cmrDebit, { ReactComponent as CmrDebit } from './icon-cmr-debit.svg';
import logoFpayBorderless, {
  ReactComponent as LogoFpayBorderless,
} from './icon-logo-fpay-borderless.svg';
import questionFilled, {
  ReactComponent as QuestionFilled,
} from './icon-question-filled.svg';
import shipping, { ReactComponent as Shipping } from './shipping.svg';
import fcom, { ReactComponent as Fcom } from './fcom.svg';
import shopping, { ReactComponent as Shopping } from './shopping.svg';
import offersWhite, { ReactComponent as OffersWhite } from './offers-white.svg';
import date, { ReactComponent as Date } from './date.svg';
import physicalCard, {
  ReactComponent as PhysicalCard,
} from './physical-card.svg';
import product, { ReactComponent as Product } from './product.svg';
import x2, { ReactComponent as X2 } from './x2.svg';
import withoutCategory, {
  ReactComponent as WithoutCategory,
} from './withoutCategory.svg';
import personalDocument, {
  ReactComponent as PersonalDocument,
} from './personalDocument.svg';
import succesFeedback, {
  ReactComponent as SuccesFeedback,
} from './succesFeedback.svg';
import giftcardIcon, {
  ReactComponent as GiftcardIcon,
} from './giftcardIcon.svg';

export const IconsMap = {
  search: { component: Search, url: search },
  plus: { component: Plus, url: plus },
  minus: { component: Minus, url: minus },
  arrowLeft: { component: ArrowLeft, url: arrowLeft },
  arrowLeftWhite: { component: ArrowLeftWhite, url: arrowLeftWhite },
  arrowRight: { component: ArrowRight, url: arrowRight },
  questionWhite: { component: QuestionWhite, url: questionWhite },
  question: { component: Question, url: question },
  cmr: { component: Cmr, url: cmr },
  cmrSmall: { component: CmrSmall, url: cmrSmall },
  cmrPuntoGray: { component: CMRPuntoGray, url: cmrPuntoGray },
  cmrPuntosGray: { component: CMRPuntosGray, url: cmrPuntosGray },
  logoutLeft: { component: LogoutLeft, url: logoutLeft },
  back: { component: Back, url: back },
  bags: { component: Bags, url: bags },
  bank: { component: Bank, url: bank },
  bankCmr: { component: BankCmr, url: bankCmr },
  bankVertical: { component: BankVertical, url: bankVertical },
  next: { component: Next, url: next },
  nextLink: { component: NextLink, url: nextLink },
  close: { component: Close, url: close },
  info: { component: Info, url: info },
  home: { component: Home, url: home },
  enter: { component: Enter, url: enter },
  signup: { component: Signup, url: signup },
  benefits: { component: Benefits, url: benefits },
  requests: { component: Requests, url: requests },
  faq: { component: Faq, url: faq },
  settings: { component: Settings, url: settings },
  chevronDown: { component: ChevronDown, url: chevronDown },
  success: { component: Success, url: success },
  warning: { component: Warning, url: warning },
  alert: { component: Alert, url: alert },
  facebook: { component: Facebook, url: facebook },
  instagram: { component: Instagram, url: instagram },
  youtube: { component: Youtube, url: youtube },
  falabellaBankLarge: {
    component: FalabellaBankLarge,
    url: falabellaBankLarge,
  },
  bankWhite: { component: BankWhite, url: bankWhite },
  bankCmrColor: { component: BankCmrColor, url: bankCmrColor },
  mobileDevice: { component: MobileDevice, url: mobileDevice },
  fan: { component: Fan, url: fan },
  elite: { component: Elite, url: elite },
  premium: { component: Premium, url: premium },
  offers: { component: Offers, url: offers },
  lock: { component: Lock, url: lock },
  unLock: { component: UnLock, url: unLock },
  inscribeteAcumula: { component: InscribeteAcumula, url: inscribeteAcumula },
  inscribeteCanjea: { component: InscribeteCanjea, url: inscribeteCanjea },
  inscribeteCategorias: {
    component: InscribeteCategorias,
    url: inscribeteCategorias,
  },
  statement: { component: Statement, url: statement },
  nextPageActive: { component: NextPageActive, url: nextPageActive },
  nextPageDisabled: { component: NextPageDisabled, url: nextPageDisabled },
  prevPageActive: { component: PrevPageActive, url: prevPageActive },
  prevPageDisabled: { component: PrevPageDisabled, url: prevPageDisabled },
  squareClose: { component: SquareClose, url: squareClose },
  email: { component: Email, url: email },
  phone: { component: Phone, url: phone },
  triangleWarning: { component: TriangleWarning, url: triangleWarning },
  falabellaBankRectangle: {
    component: FalabellaBankRectangle,
    url: falabellaBankRectangle,
  },
  calendar: { component: Calendar, url: calendar },
  paperClip: { component: PaperClip, url: paperClip },
  trash: { component: Trash, url: trash },
  fourOhFour: { component: FourOhFour, url: fourOhFour },
  arrowSliderRightWhite: {
    component: ArrowSliderRightWhite,
    url: arrowSliderRightWhite,
  },
  arrowSliderLeftWhite: {
    component: ArrowSliderLeftWhite,
    url: arrowSliderLeftWhite,
  },
  offline: { component: Offline, url: offline },
  noCmrPoints: { component: NoCmrPoints, url: noCmrPoints },
  fpay: { component: Fpay, url: fpay },
  otrosMediosPago: { component: OtrosMediosPago, url: otrosMediosPago },
  tarjetaCMRBF: { component: TarjetaCMRBF, url: tarjetaCMRBF },
  tarjetaDebitoBF: { component: TarjetaDebitoBF, url: tarjetaDebitoBF },
  buyNowPayLater: { component: BuyNowPayLater, url: buyNowPayLater },
  fpayLogoApp: { component: FpayLogoApp, url: fpayLogoApp },
  botonGooglePlay: { component: BotonGooglePlay, url: botonGooglePlay },
  botonAppStore: { component: BotonAppStore, url: botonAppStore },
  falabellaGroup: { component: FalabellaGroup, url: falabellaGroup },
  falabellaCMRGroup: { component: FalabellaCMRGroup, url: falabellaCMRGroup },
  suggestions: { component: Suggestions, url: suggestions },
  falabellaGroupLogin: {
    component: FalabellaGroupLogin,
    url: falabellaGroupLogin,
  },
  falabellaGroupLoginReg: {
    component: FalabellaGroupLoginReg,
    url: falabellaGroupLoginReg,
  },
  cmrPuntosCircle: { component: CmrPuntosCircle, url: cmrPuntosCircle },
  eliteLine: { component: EliteLine, url: eliteLine },
  eliteOnly: { component: EliteOnly, url: eliteOnly },
  fanLine: { component: FanLine, url: fanLine },
  fanOnly: { component: FanOnly, url: fanOnly },
  premiumLine: { component: PremiumLine, url: premiumLine },
  premiumOnly: { component: PremiumOnly, url: premiumOnly },
  loadFailed: { component: LoadFailed, url: loadFailed },
  myExchanges: { component: MyExchanges, url: myExchanges },
  profile: { component: Profile, url: profile },
  download: { component: Download, url: download },
  arrowNext: { component: ArrowNext, url: arrowNext },
  logoCmrpuntos: { component: LogoCmrpuntos, url: logoCmrpuntos },
  cmrCredit: { component: CmrCredit, url: cmrCredit },
  cmrDebit: { component: CmrDebit, url: cmrDebit },
  logoFpayBorderless: {
    component: LogoFpayBorderless,
    url: logoFpayBorderless,
  },
  questionFilled: { component: QuestionFilled, url: questionFilled },
  shipping: { component: Shipping, url: shipping },
  fcom: { component: Fcom, url: fcom },
  shopping: { component: Shopping, url: shopping },
  offersWhite: { component: OffersWhite, url: offersWhite },
  date: { component: Date, url: date },
  physicalCard: { component: PhysicalCard, url: physicalCard },
  product: { component: Product, url: product },
  x2: { component: X2, url: x2 },
  withoutCategory: { component: WithoutCategory, url: withoutCategory },
  personalDocument: { component: PersonalDocument, url: personalDocument },
  succesFeedback: { component: SuccesFeedback, url: succesFeedback },
  giftcardIcon: { component: GiftcardIcon, url: giftcardIcon },
};

// Definitions
const IconSizes = {
  tiny: {
    height: '24px',
    width: ' 24px',
  },
  small: {
    height: '32px',
    width: ' 32px',
  },
  medium: {
    height: '48px',
    width: ' 48px',
  },
  large: {
    height: '64px',
    width: ' 64px',
  },
};

export type IconName = keyof typeof IconsMap;
export type IconSize = keyof typeof IconSizes;

export type Props = {
  name?: IconName;
  size?: IconSize;
  ariaHidden?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
} & StyledExtendable;

const Icon = (props: Props) => {
  const { name, size, ariaHidden = false, style, className, onClick } = props;
  if (!name) return <></>;

  const styleSizes = size ? IconSizes[size] : {};
  const Icon = IconsMap[name].component;
  return (
    <Icon
      onClick={onClick}
      role="img"
      aria-label={name}
      aria-hidden={ariaHidden}
      className={className}
      style={{ ...styleSizes, ...style }}
    />
  );
};

export default Icon;
