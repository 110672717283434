import styled, { css } from 'styled-components';
import { ContainerProps, maxCenteredCards } from './AlliesSection.defs';

const Styled = {
  Container: styled.section<ContainerProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 0 5rem 1rem;

    @media ${({ theme }) => theme.devices.tablet} {
      align-items: center;
      padding: 2rem 0 5rem 2rem;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      align-items: center;
      padding: 2rem 0 3rem 2rem;
    }

    @media (min-width: 1920px) {
      padding: 2rem 2rem 3rem 2rem;
    }

    h4 {
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: #44474b;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      h4 {
        font-weight: 300;
        font-size: 32px;
        line-height: 140%;
      }
    }

    @media ${({ theme }) => theme.devices.desktop} {
      h4 {
        font-weight: 300;
        font-size: 32px;
        line-height: 140%;
      }
    }

    .swiper-wrapper {
      ${({ numberOfCards }) =>
        numberOfCards < maxCenteredCards.mobile &&
        css`
          @media ${({ theme }) => theme.devices.mobile} {
            justify-content: center;
          }
          .swiper-slide {
            max-width: 200px;
          }
        `}

      ${({ numberOfCards }) =>
        numberOfCards < maxCenteredCards.tablet &&
        css`
          @media ${({ theme }) => theme.devices.tablet} {
            justify-content: center;
          }

          .swiper-slide {
            max-width: 200px;
          }
        `}

      ${({ numberOfCards }) =>
        numberOfCards < maxCenteredCards.desktop &&
        css`
          @media ${({ theme }) => theme.devices.desktop} {
            justify-content: center;
          }

          .swiper-slide {
            max-width: 200px;
          }
        `}

        ${({ numberOfCards }) =>
        numberOfCards < maxCenteredCards.wideDesktop &&
        css`
          @media ${({ theme }) => theme.devices.wideDesktop} {
            justify-content: center;

            @media (min-width: 1920px) {
              justify-content: center;
            }
          }

          .swiper-slide {
            max-width: 200px;
          }
        `}
    }
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 22px;
    color: #323537;

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }
  `,
  WrapperCardsGroup: styled.div`
    width: 100%;

    @media (min-width: 1920px) {
      max-width: 1870px;
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          height: auto;
        }
      }
    }
  `,
  Card: styled.div`
    background: #f4f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: '2px solid #F4F7F9';
    border-radius: 16px;
    width: 200px;
    height: 120px;
    cursor: pointer;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: fill;
    }
  `,
};

export default Styled;
