import styled from 'styled-components';

export const Container = styled.footer`
  background-color: var(--color-bg-dark);
`;

export const Content = styled.div`
  @media screen and ${({ theme }) => theme.devices.tablet} {
    margin-right: 216px;
    padding: 40px 0 0 80px;
    min-height: 100px;
  }
`;

export const Complementary = styled.aside`
  padding: 32px 24px;

  & > * {
    margin-right: 16px;
  }

  @media screen and ${({ theme }) => theme.devices.tablet} {
    float: right;
  }
`;

export const Copyright = styled.div`
  padding: 32px 24px;
  display: flex;
  align-items: flex-start;
  width: 100%;

  div {
    align-self: center;
  }

  small {
    font-size: 12px;
    color: var(--color-white);
    margin-left: 16px;
    white-space: pre-line;
    display: block;

    &:nth-child(2) {
      margin-top: 6px;
    }
  }

  @media screen and ${({ theme }) => theme.devices.tablet} {
    padding: 32px 80px;
    border-top: #475059 0.5px solid;
  }
`;
