import { useState } from 'react';

// services
import Affiliates from 'infrastructure/services/loyalty/resources/affiliates';

// components
import Spinner from 'presentation/components/atoms/Spinner/Spinner';
import BrandForm from './form/BrandForm';
import FeebackFormBrand from './feedback/FeebackFormBrand';

// Styles
import Styled from './StepsFormBrand.styled';

export enum STEP {
  LOADING,
  FORM_BRAND,
  FEEDBACK_SUCCESS,
  FEEDBACK_ERROR,
}

type StepsFormBrandProps = {
  handleModalClose: () => void;
};

const StepsFormBrand = ({ handleModalClose }: StepsFormBrandProps) => {
  const [steps, setSteps] = useState<STEP>(STEP.FORM_BRAND);

  const submitFormBrand = async (brandFormData: any, tokenCaptcha: string) => {
    setSteps(STEP.LOADING);

    const request = { ...brandFormData, tokenCaptcha };
    try {
      await Affiliates.save(request);
      setSteps(STEP.FEEDBACK_SUCCESS);
    } catch {
      setSteps(STEP.FEEDBACK_ERROR);
    }
  };

  return (
    <Styled.Container>
      {steps === STEP.FORM_BRAND ? (
        <BrandForm submitFormBrand={submitFormBrand} />
      ) : null}

      {steps === STEP.LOADING ? (
        <Styled.WrapperSpinner>
          <Spinner />
        </Styled.WrapperSpinner>
      ) : null}

      {steps === STEP.FEEDBACK_SUCCESS ? (
        <FeebackFormBrand type="success" handleModalClose={handleModalClose} />
      ) : null}
      {steps === STEP.FEEDBACK_ERROR ? (
        <FeebackFormBrand type="error" handleModalClose={handleModalClose} />
      ) : null}
    </Styled.Container>
  );
};

export default StepsFormBrand;
