import {
  IShortCustomInformation,
  NoShortCustomInformation,
} from 'domain/model/content';

const ShortCustomInformationMapper = (json: any): IShortCustomInformation => {
  try {
    const { fields } = json;
    const { title, description } = fields;

    return {
      title,
      description,
    };
  } catch {
    console.warn(
      '[ShortCustomInformationMapper] The content json structure received differed from expected',
    );

    return NoShortCustomInformation;
  }
};

export default ShortCustomInformationMapper;
