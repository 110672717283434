import { CustomerCategoryName } from 'domain/model/customer';
import styled, { css } from 'styled-components';

const getBackgroundColorIcon = (type?: CustomerCategoryName) => {
  if (type === CustomerCategoryName.Fan) {
    return 'background-color: #d60942';
  }
  if (type === CustomerCategoryName.Premium) {
    return 'background-color: #747e81';
  }

  if (type === CustomerCategoryName.Elite) {
    return 'background-color: #000000';
  }
};

const getMarginTopLastItem = (country: string, type: CustomerCategoryName) => {
  if (country === 'cl' && type === CustomerCategoryName.Fan) {
    return 'margin-top: 37px;';
  }

  if (country === 'cl' && type === CustomerCategoryName.Premium) {
    return 'margin-top: 46px;';
  }

  if (country === 'cl' && type === CustomerCategoryName.Elite) {
    return 'margin-top: 0;';
  }

  if (country === 'pe' || country === 'co') {
    return 'margin-top: 24px;';
  }
};

const Styled = {
  WrapperCard: styled.div`
    height: 100%;
    position: relative;
    min-width: 230px;

    @media ${({ theme }) => theme.devices.tablet} {
      width: 360px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      width: 360px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      width: 360px;
    }
  `,
  Card: styled.div`
    height: 100%;
    border: 0.5px solid #c0ced6;
    border-color: '#cfdbe6';
    box-shadow: 0px 4px 2px rgba(0, 51, 102, 0.05);
    border-radius: 16px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  `,

  Header: styled.header<{ type: CustomerCategoryName }>`
    height: 188px;
    padding: 48px 24px 24px;
    display: flex;
    justify-content: center;
    ${({ type }) =>
      type === CustomerCategoryName.Fan &&
      css`
        background-color: #fff2f2;
        color: #d60942;
      `}
    ${({ type }) =>
      type === CustomerCategoryName.Premium &&
      css`
        background-color: #f4f7f9;
        color: #5d6467;
      `}
      ${({ type }) =>
      type === CustomerCategoryName.Elite &&
      css`
        background-color: #e2e9ee;
        color: #000000;
      `};
  `,
  AccumulatingText: styled.p`
    font-weight: 600;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: -0.01em;
    text-align: center;
    text-transform: uppercase;
  `,
  WrapperPoints: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Points: styled.p`
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    letter-spacing: -0.02em;
    margin-right: 10px;
  `,
  AcumulationInformationText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;

    @media (max-width: 424px) {
      font-size: 14px;
    }
  `,
  BadgeCategory: styled.div`
    display: flex;
    align-items: center;
    padding: 10px 24px;
    gap: 8px;
    position: absolute;
    width: auto;
    height: 64px;
    top: -30px;
    background: #ffffff;
    border: 1px solid #c0ced6;
    box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
      0px 3px 6px rgba(0, 51, 102, 0.05);
    border-radius: 32px;
    font-size: 32px;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.01em;
  `,
  Content: styled.div`
    padding: 28px 12px 16px 12px;
  `,
  BenefitsList: styled.ul``,
  BenefitsItem: styled.li<{ country: string; type: CustomerCategoryName }>`
    display: flex;
    align-items: start;
    gap: 16px;
    margin-bottom: 12px;
    &:last-child {
      ${({ country, type }) =>
        country && type && getMarginTopLastItem(country, type)}
    }
  `,
  WrapperIcon: styled.div<{
    type?: CustomerCategoryName;
    outlineIcon?: boolean;
  }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    ${({ type, outlineIcon }) => !outlineIcon && getBackgroundColorIcon(type)};
  `,
  BenefitsText: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #323537;

    svg {
      margin: 0 5px;
    }

    @media (max-width: 424px) {
      font-size: 14px;
      svg {
        width: 74px;
        margin-left: 0;
      }
    }

    a {
      font-weight: 450;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: #474c4e;
      margin-left: 5px;
    }
  `,
  DescriptionItem: styled.span`
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #474c4e;
    @media (max-width: 424px) {
      font-size: 12px;
    }
  `,
  Footer: styled.footer`
    width: 100%;
    padding: 0 16px 24px;
    text-align: center;

    a {
      width: 80%;
    }

    button {
      width: 100%;
      border-radius: 128px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 171.4%;
      letter-spacing: -0.01em;
    }
  `,
};

export default Styled;
