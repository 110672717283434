import * as React from 'react';
import { ChangeEvent, CSSProperties, ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

// Definitions
export type Options = {
  id: string;
  value?: number | string;
  text: string;
  selected?: boolean;
  disabled?: boolean;
};

export type Props = {
  id?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  options: Options[];
  style?: CSSProperties;
  onChange?: (value?: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

type RenderProps = Omit<Props, 'onChange' | 'options'> & {
  children?: any;
};

const Select = (props: Props, ref: ForwardedRef<HTMLSelectElement>) => {
  const {
    id,
    name,
    value,
    disabled,
    error,
    options,
    style,
    onChange,
    onBlur,
    onFocus,
  } = props;

  const methods = {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => {
      if (!onChange) return;

      const currentValue = event.target.value?.toString().trim();
      onChange(currentValue);
    },
  };

  return (
    <RenderSelect
      data-testid={id}
      name={name}
      error={error}
      disabled={disabled}
      value={value}
      style={style}
      ref={ref}
      onChange={methods.onChange}
      onBlur={onBlur}
      onFocus={onFocus}>
      {options.map(option => {
        const { id: optionId, value, text, disabled } = option;
        return (
          <option
            key={`option-${optionId}-${value}`}
            value={value}
            disabled={disabled}>
            {text}
          </option>
        );
      })}
    </RenderSelect>
  );
};

// Styles
const RenderSelect = styled.select<RenderProps>`
  font-family: 'pfbeausans';
  width: 100%;
  height: 48px;
  border: 1px solid #cfdae6;
  border-radius: 6px;
  font-size: 16px;
  text-indent: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: var(--color-text-primary);
  ${({ error }) => error && 'border-color: var(--color-error);'}

  &:focus {
    background-color: #ffffff;
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-text-placeholder);
    letter-spacing: -0.2px;
  }

  outline: none;
  outline-style: none;
  appearance: none;
  background-image: var(--select-arrow);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-color: #ffffff;

  &:invalid {
    color: var(--color-text-placeholder);
  }
`;

export default forwardRef(Select);
