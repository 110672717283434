import * as React from 'react';
import { ReactElement, cloneElement } from 'react';

// Domain
import { FormText } from 'domain/configuration/form';

// Utils
import { ariaAttr } from 'presentation/utils/aria';

// Components
import TextRenderer from '../TextRenderer';

// Styles
import * as Styled from './FormControl.styles';

// Definitions
export type InputOptions = {
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
};

export type Props = {
  id?: string;
  label?: string;
  hintText?: string | FormText | FormText[];
  message?: string;
  children: ReactElement<InputOptions & HTMLElement>;
} & InputOptions;

const FormControl = (props: Props) => {
  const {
    id,
    label,
    hintText,
    message,
    isDisabled,
    isInvalid,
    isRequired,
    children,
  } = props;

  // Variables
  const showLabel = !!label;
  const showHintText = !!hintText;
  const showMessage = isInvalid && message;
  const messageId = (showMessage && id && `${id}-message`) || undefined;

  const InputElement = cloneElement(children, {
    id,
    isDisabled,
    isInvalid,
    isRequired,
    ...ariaAttr('errormessage', messageId),
  });

  return (
    <Styled.FormControl>
      {showLabel && (
        <Styled.FormControlLabel htmlFor={id}>{label}</Styled.FormControlLabel>
      )}
      {!!showHintText && (
        <Styled.FormControlHintText>
          <TextRenderer value={hintText ?? ''} />
        </Styled.FormControlHintText>
      )}
      {InputElement}
      <Styled.FormControlMessage
        id={messageId}
        data-testid={messageId}
        {...ariaAttr('hidden', !showMessage)}>
        <p>{message}</p>
      </Styled.FormControlMessage>
    </Styled.FormControl>
  );
};

export default FormControl;
