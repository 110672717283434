import { AxiosResponse, CancelTokenSource } from 'axios';

// Utils
import Random from 'infrastructure/utils/random';

// Client
import { httpClient, token as clientToken } from '../../client';

// Definitions
import { ExtendConfig } from '../../definitions';
import {
  AffiliatesSaveRequest,
  AffiliatesSaveResponse,
} from './Affiliates.defs';

// Dynamic token source
let source: CancelTokenSource;

const Affiliates = {
  save: (
    request: AffiliatesSaveRequest,
  ): Promise<AxiosResponse<AffiliatesSaveResponse>> => {
    source = clientToken.source();

    const {
      contactName,
      contactPhone,
      enterpriseEmail,
      enterpriseName,
      tokenCaptcha: recaptchaToken,
    } = request;

    const data = {
      contactName,
      contactPhone,
      enterpriseEmail,
      enterpriseName,
      recaptchaToken,
    };

    const encrypt = {
      secret: Random.string(32),
      iv: Random.string(16),
      encode: true,
    };
    const commerceHeader = { 'x-commerce': 'CMR', 'x-channel': 'Web' };

    return httpClient.post('v1/affiliate', data, {
      cancelToken: source.token,
      encrypt,
      headers: commerceHeader,
    } as ExtendConfig);
  },
};

export default Affiliates;
