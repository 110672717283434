import { useEffect, useState, useRef } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

// Event bus
import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Hooks

// Providers
import { useAuth } from 'infrastructure/providers/Auth';

// External definitions
import { ISlide, ISlidesGroup } from 'domain/model/content';

// Templates
import { Carousel } from 'presentation/components/molecules';
import Slide from './slide/Slide';

// Styles
import Container from './MainSlider.style';

// Configure Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

// Definitions
export type Props = {
  id: string;
  content?: ISlidesGroup;
};

const MODULE_NAME = 'Carrusel_banner';
const EVENT_SUBJECT = 'Open main carrousel banner slide';

const MainSlider = (props: Props) => {
  // Props
  const { id, content } = props;
  const banners = content?.slides;
  const {
    data: { logged },
  } = useAuth();

  // Hooks
  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  // States
  const [currentSlides, setCurrentSlides] = useState<ISlide[]>([]);
  const [swiperKey, setSwiperKey] = useState(0);

  // Events
  const events = {
    slideOpen: (slideName: string, target: string) => ({
      subject: EVENT_SUBJECT,
      module: MODULE_NAME,
      action: slideName,
      target,
    }),
    onAllImageLoaded: () => {
      if (swiperKey === 0) {
        setTimeout(() => setSwiperKey(prev => prev + 1), 1500);
      }
    },
  };

  const isLoop = useRef(false);

  // On external slides changes
  useEffect(() => {
    if (banners && banners.length) {
      setCurrentSlides(banners);
      isLoop.current = banners?.length > 1;
    }
  }, [banners]);

  return (
    <Container>
      {currentSlides.length > 0 && (
        <Carousel
          id={id}
          swiperOptions={{
            autoplay: { delay: 6000, stopOnLastSlide: false },
            loop: isLoop.current,
            breakpoints: {
              768: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
            },
            pagination: { clickable: true },
            navigation: true,
          }}>
          {currentSlides.map(slide => {
            const {
              id: slideId,
              name,
              title,
              description,
              link,
              color,
              image,
              visibility,
            } = slide;
            const show =
              (logged.in && visibility === 'private') ||
              (!logged.in && visibility === 'public') ||
              visibility === 'all';

            return (
              show && (
                <Slide
                  title={title}
                  key={slideId}
                  description={description}
                  link={link}
                  color={color}
                  image={image}
                  onClick={target => publish(events.slideOpen(name, target))}
                  ecosystem={[]}
                />
              )
            );
          })}
        </Carousel>
      )}
    </Container>
  );
};

export default MainSlider;
