/* eslint-disable camelcase */
import {
  Analytics,
  GA4ViewEvents,
  GA4BusinessEvents,
  GA4ProductEvents,
  BusinessProduct,
  FunnelStepViewed,
  ErrorTriggered,
} from '@df/fif-common-analytics';
import Configuration from 'infrastructure/stores/Configuration';

type EnvGA = 'test' | 'qa' | 'prod';

// Constants
const ENV = process.env.REACT_APP_ANALYTICS_ENV as EnvGA;
const KEY = process.env.REACT_APP_ANALYTICS_KEY as string;

// Initialize
const gtm = new Analytics.TaggingAdapter();
const viewEvents = new GA4ViewEvents();
const businessEvents = new GA4BusinessEvents();
const productEvents = new GA4ProductEvents();

const labelAnalytics = () => {
  const configuration = Configuration.get();
  if (!configuration) return '';
  const { client, platform, device } = configuration;

  if (client.toLowerCase() === 'linio') {
    return `SSO-WL-${platform}-${device}`;
  }

  return `${client}-${platform}-${device}`;
};

// Methods
const methods = {
  Load: async () => Analytics.TaggingAdapter.dynamicallyLoadProvider(ENV, KEY),
  setPage: (page: string) => gtm.tagPage(page),
  setEventUI: (category: string, action: string, label?: string) => {
    gtm.tagEvent(`ui :: ${category}`, label ?? labelAnalytics(), action);
  },
  setEventOP: (category: string, action: string) => {
    gtm.tagEvent(`op :: ${category}`, labelAnalytics(), action);
  },
  setError: (action: string) => {
    gtm.tagEvent('errors', labelAnalytics(), action);
  },
  pageView(pageLocation: string, pageTitle: string) {
    viewEvents.pageView({
      page_location: pageLocation,
      page_title: pageTitle,
    });
  },
  userInteraction(action: string, location: string, flow: string) {
    viewEvents.userInteraction({
      action,
      location,
      flow,
    });
  },
  errorTriggered({
    error_type,
    error_service_name,
    error_message,
  }: ErrorTriggered) {
    viewEvents.errorTriggered({
      error_type,
      error_service_name,
      error_message,
    });
  },
  funnelStepViewed(funnelEvent: FunnelStepViewed) {
    viewEvents.funnelStepViewed({ ...funnelEvent });
  },
  creditCardAcquired({ product_name, funnel_name }: BusinessProduct) {
    businessEvents.creditCardAcquired({
      product_name,
      funnel_name,
    });
  },
  checkingAccountAcquired({ product_name, funnel_name }: BusinessProduct) {
    businessEvents.checkingAccountAcquired({
      product_name,
      funnel_name,
    });
  },
  insuranceAcquired({ product_name, funnel_name }: BusinessProduct) {
    businessEvents.insuranceAcquired({
      product_name,
      funnel_name,
    });
  },
  pointsRedemptionCompleted({ product_name, funnel_name }: BusinessProduct) {
    businessEvents.pointsRedemptionCompleted({
      product_name,
      funnel_name,
    });
  },
  userIdentified(user_id: string) {
    productEvents.userIdentified({
      user_id,
    });
  },
};

export default methods;
