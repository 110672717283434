import { useMemo } from 'react';
import {
  FormInput,
  FormText,
  FormButton,
} from 'domain/configuration/form/Form.defs';

import Configuration from 'domain/configuration/form/brand';

// Definitions
import { ValidatorFunc } from 'domain/validations';

const getBrandFormConfig = () => {
  const { form, methods: configuration } = Configuration();

  const { id } = form;
  const title = configuration.getText('title') as FormText;
  const subtitle = configuration.getText('subtitle') as FormText;
  const fields = {
    enterpriseName: configuration.getComponent('enterpriseName') as FormInput,
    contactName: configuration.getComponent('contactName') as FormInput,
    contactPhone: configuration.getComponent('contactPhone') as FormInput,
    enterpriseEmail: configuration.getComponent('enterpriseEmail') as FormInput,
  };
  const buttons = {
    submit: configuration.getComponent('submit') as FormButton,
  };
  const errors = {
    enterpriseNameInvalid: configuration.getError('03')?.description as string,
    contactNameInvalid: configuration.getError('04')?.description as string,
    phoneInvalid: configuration.getError('01')?.description as string,
    emailInvalid: configuration.getError('02')?.description as string,
  };

  return {
    id,
    title,
    subtitle,
    fields,
    buttons,
    errors,
  };
};

const validateInput = (value: string, validator?: ValidatorFunc): boolean => {
  if (!validator) return true;

  const validatorFunc = validator as ValidatorFunc;

  return validatorFunc(value).valid;
};

const useFormConfig = () => {
  const formConfig = useMemo(() => getBrandFormConfig(), []);

  const { fields, errors } = formConfig;

  // Fields validation rules
  const validationRules = useMemo(
    () => ({
      enterpriseName: {
        required: errors.enterpriseNameInvalid,
        minLength: fields.enterpriseName.minLength && {
          value: fields.enterpriseName.minLength.value,
          message: errors.enterpriseNameInvalid,
        },
        maxLength: fields.enterpriseName.maxLength && {
          value: fields.enterpriseName.maxLength.value,
          message: errors.enterpriseNameInvalid,
        },
      },
      contactName: {
        required: errors.contactNameInvalid,
        minLength: fields.contactName.minLength && {
          value: fields.contactName.minLength.value,
          message: errors.contactNameInvalid,
        },
        maxLength: fields.contactName.maxLength && {
          value: fields.contactName.maxLength.value,
          message: errors.contactNameInvalid,
        },
      },
      phone: {
        required: errors.phoneInvalid,
        minLength: fields.contactPhone.minLength && {
          value: fields.contactPhone.minLength.value,
          message: errors.phoneInvalid,
        },
        maxLength: fields.contactPhone.maxLength && {
          value: fields.contactPhone.maxLength.value,
          message: errors.phoneInvalid,
        },
        validate: {
          invalid: (value: string) =>
            validateInput(
              value,
              fields.contactPhone.validator as ValidatorFunc,
            ) || errors.phoneInvalid,
        },
      },
      email: {
        required: errors.emailInvalid,
        validate: {
          invalid: (value: string) =>
            validateInput(
              value,
              fields.enterpriseEmail.validator as ValidatorFunc,
            ) || errors.emailInvalid,
        },
      },
    }),
    [fields, errors],
  );

  return {
    formConfig,
    validationRules,
  };
};

export default useFormConfig;
