import styled, { css } from 'styled-components';
import {
  RoundBorderMixin,
  TextDefaultFontMixin,
} from 'presentation/utils/styles/Mixins';

type ThemingOptions = {
  focus?: boolean;
  invalid?: boolean;
  disabled?: boolean;
};

// Input addon
export const InputAddon = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  background-color: var(--color-bg-info);
`;

// Input addon
export const InputHelp = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
`;

// Input
export const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: var(--color-white);
  border: none;
  text-indent: 16px;
  outline: 0;
  box-shadow: none;

  &::placeholder {
    color: var(--color-text-placeholder);
    opacity: 1;
    letter-spacing: -0.2px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type='password'] {
    letter-spacing: 5px;
  }
`;

// States styles
const FocusStateStyle = css`
  border-color: var(--color-border-focus);
`;

const DisabledStateStyle = css`
  color: var(--color-text-disabled) !important;

  & > ${InputAddon} {
    border-right: 1px solid var(--color-border-default);
  }

  & > * {
    background-color: var(--color-bg-disabled);
  }
`;

const InvalidStateStyle = css`
  border-color: var(--color-border-error) !important;

  & > * {
    background-color: var(--color-bg-error);
  }

  & > ${InputAddon} ~ * {
    background-color: var(--color-white);
  }
`;

// Input group
export const InputGroup = styled.div<ThemingOptions>`
  ${TextDefaultFontMixin};
  ${RoundBorderMixin}

  display: flex;
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: var(--color-text-primary);
  border: 1px solid var(--color-border-default);
  overflow: hidden;

  &:focus-within {
    ${FocusStateStyle}
  }

  // Invalid style
  ${({ invalid }) => invalid && InvalidStateStyle}

  //Disabled style
  ${({ disabled }) => disabled && DisabledStateStyle}
`;
