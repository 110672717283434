import styled from 'styled-components';

// Style utils
import {
  TextSizeMixin,
  TextWeightMixin,
} from 'presentation/utils/styles/Mixins';

// Definitions
export type VariantTokens = {
  colors: {
    text: string;
    heading: string;
    headingBackground: string;
  };
  spacing?: {
    heading: string;
    content: string;
  };
};

const TOKENS: { [key: string]: VariantTokens } = {
  light: {
    colors: {
      text: '#8b929b',
      heading: '#8b929b',
      headingBackground: '#fbfcfd',
    },
    spacing: {
      heading: '18px 30px 15px 24px',
      content: '24px',
    },
  },
  dark: {
    colors: {
      text: 'var(--color-white)',
      heading: 'var(--color-text-placeholder)',
      headingBackground: 'var(--color-bg-dark)',
    },
  },
};

export const getVariantTokens = (variant: string): VariantTokens =>
  TOKENS[variant];

export const Nav = styled.nav<{ tokens: VariantTokens }>`
  color: ${({ tokens }) => tokens.colors.text};
`;

export const Heading = styled.h3<{ tokens: VariantTokens }>`
  ${TextWeightMixin('semi-bold')};

  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: ${({ tokens }) => tokens.colors.heading};
  background-color: ${({ tokens }) => tokens.colors.headingBackground};
  padding: ${({ tokens }) => tokens.spacing && tokens.spacing.heading};
`;

export const Group = styled.ul``;

export const GroupItem = styled.li<{ tokens: VariantTokens }>`
  list-style-type: none;
  margin-bottom: 8px;
  padding-left: ${({ tokens }) => tokens.spacing && tokens.spacing.content};

  a {
    ${TextWeightMixin('regular')};
    ${TextSizeMixin('regular')};

    text-decoration: none;
    color: ${({ tokens }) => tokens.colors.text};
    box-decoration-break: clone;
  }
`;
