import { useRef } from 'react';
import { useForm } from 'react-hook-form';

// Components
import Button from 'presentation/components/atoms/Button';
import Form, { Group, Header } from 'presentation/components/organisms/Form';
import FormControl from 'presentation/components/molecules/FormControl';
import Input from 'presentation/components/atoms/Input3';
import Captcha, { RefProps } from 'presentation/components/atoms/Captcha';

// utils
import useFormConfig from './BrandForm.utils';

// Styles
import Styled from './BranForm.styled';

type BrandFormProps = {
  submitFormBrand: (brandFormData: any, tokenCaptcha: string) => void;
};

const BrandForm = ({ submitFormBrand }: BrandFormProps) => {
  const captchaRef = useRef<RefProps>();
  const { formConfig, validationRules } = useFormConfig();
  const { fields, buttons } = formConfig;

  const { formState, getValues, register, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      contactName: '',
      enterpriseName: '',
      contactPhone: '',
      enterpriseEmail: '',
    },
  });

  const contactNameField = register('contactName', validationRules.contactName);
  const enterpriseNameField = register(
    'enterpriseName',
    validationRules.enterpriseName,
  );
  const phoneField = register('contactPhone', validationRules.phone);
  const emailField = register('enterpriseEmail', validationRules.email);

  const { errors: formErrors } = formState;

  const validateCaptcha = () => {
    captchaRef?.current?.execute();
  };

  const onSubmit = () => {
    validateCaptcha();
  };

  const handleSubmitForm = (captchaToken: string) => {
    submitFormBrand(getValues(), captchaToken);
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Header
            title="Solicitud de Marca"
            subtitle="Y únete al programa CMR Puntos"
            options={{ alignText: 'center' }}
          />

          <FormControl
            id={fields.contactName.id}
            label={fields.contactName.label}
            isInvalid={!!formErrors.contactName}
            message={formErrors.contactName?.message}>
            <Input
              {...contactNameField}
              type={fields.contactName.type}
              inputMode={fields.contactName.mode}
              placeholder={fields.contactName.placeholder}
              minLength={fields.contactName.minLength?.value}
              maxLength={fields.contactName.maxLength?.value}
              addon={fields.contactName.addonText}
              helpText={fields.contactName.help}
              formatter={fields.contactName.formatter}
              onChange={e => {
                contactNameField.onChange(e);
              }}
            />
          </FormControl>

          <FormControl
            id={fields.contactName.id}
            label={fields.enterpriseName.label}
            isInvalid={!!formErrors.enterpriseName}
            message={formErrors.enterpriseName?.message}>
            <Input
              {...enterpriseNameField}
              type={fields.enterpriseName.type}
              inputMode={fields.enterpriseName.mode}
              placeholder={fields.enterpriseName.placeholder}
              minLength={fields.enterpriseName.minLength?.value}
              maxLength={fields.enterpriseName.maxLength?.value}
              addon={fields.enterpriseName.addonText}
              helpText={fields.enterpriseName.help}
              formatter={fields.enterpriseName.formatter}
              onChange={e => {
                enterpriseNameField.onChange(e);
              }}
            />
          </FormControl>

          <FormControl
            id={fields.contactPhone.id}
            label={fields.contactPhone.label}
            isInvalid={!!formErrors.contactPhone}
            message={formErrors.contactPhone?.message}>
            <Input
              {...phoneField}
              type={fields.contactPhone.type}
              inputMode={fields.contactPhone.mode}
              placeholder={fields.contactPhone.placeholder}
              minLength={fields.contactPhone.minLength?.value}
              maxLength={fields.contactPhone.maxLength?.value}
              addon={fields.contactPhone.addonText}
              helpText={fields.contactPhone.help}
              formatter={fields.contactPhone.formatter}
              onChange={e => {
                phoneField.onChange(e);
              }}
            />
          </FormControl>

          <FormControl
            id={fields.enterpriseEmail.id}
            label={fields.enterpriseEmail.label}
            isInvalid={!!formErrors.enterpriseEmail}
            message={formErrors.enterpriseEmail?.message}>
            <Input
              {...emailField}
              type={fields.enterpriseEmail.type}
              inputMode={fields.enterpriseEmail.mode}
              placeholder={fields.enterpriseEmail.placeholder}
              minLength={fields.enterpriseEmail.minLength?.value}
              maxLength={fields.enterpriseEmail.maxLength?.value}
              addon={fields.enterpriseEmail.addonText}
              helpText={fields.enterpriseEmail.help}
              formatter={fields.enterpriseEmail.formatter}
              onChange={e => {
                emailField.onChange(e);
              }}
            />
          </FormControl>

          <Group>
            <Button
              id={buttons.submit.id}
              variant="primary"
              isFullWidth
              type="submit">
              {buttons.submit.text}
            </Button>
            <Captcha
              ref={captchaRef}
              onValidate={captchaToken =>
                handleSubmitForm(captchaToken as string)
              }
            />
          </Group>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default BrandForm;
