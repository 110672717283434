import styled from 'styled-components';

const Styled = {
  Container: styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 3rem;

    @media ${({ theme }) => theme.devices.tablet} {
      align-items: center;
      padding: 0px 48px 64px 48px;
      padding: 0 0 5rem 2rem;
      gap: 1.5rem;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      align-items: center;
      padding: 124px 124px 80px 124px;
      gap: 4rem;
    }
  `,
  WrapperCarousel: styled.div`
    width: 100%;
    height: 100%;

    @media ${({ theme }) => theme.devices.mobile} {
      .swiper-slide {
        max-height: 90px;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding-left: 0px;

      .swiper-slide {
        max-height: 174px;
      }
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 1024px;
      padding-left: 16px;

      .swiper-slide {
        max-height: 174px;
      }
      .swiper-wrapper {
        justify-content: space-between;
      }
    }
  `,
  Card: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 0.5rem;

    @media ${({ theme }) => theme.devices.tablet} {
      flex-direction: column;
      gap: 1.3rem;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      flex-direction: column;
      gap: 1.3rem;
    }

    img {
      width: 80px;
      height: 80px;

      @media ${({ theme }) => theme.devices.tablet} {
        width: 100px;
        height: 100px;
      }

      @media ${({ theme }) => theme.devices.desktop} {
        width: 100px;
        height: 100px;
      }
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #5c6166;

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  `,
  Action: styled.div`
    width: 90%;

    button {
      min-width: 200px;
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 0 14rem;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 20rem;
    }
  `,
};

export default Styled;
