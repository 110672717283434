import { memo, useState } from 'react';

// Components
import { Title } from 'presentation/components/atoms';
import { ICard } from 'domain/model/content';
import { Carousel } from 'presentation/components/molecules';
import useIsMobile from 'infrastructure/hooks/IsMobile/IsMobile';
import Button from 'presentation/components/atoms/Button';

import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Styled Components
import Styled from './BrandSection.styled';
import ModalFormBrand from '../ModalFormBrand';

const breakpoints = {
  414: { slidesPerView: 1.7 },
  480: { slidesPerView: 2.5 },
  768: { slidesPerView: 3.5 },
};

type BrandSectionProps = {
  data: { name: string; cards: ICard[] };
};

const BrandSection = ({ data }: BrandSectionProps) => {
  const isMobile = useIsMobile();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const size = isMobile ? 'small' : 'large';

  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  const handleClickOpenModal = () => {
    setOpenModal(true);
    publish({
      module: 'seccion_captacion_aliados',
      action: 'subscriber_ally_clicked',
      subject: '',
      target: 'quiero_ser_aliado',
    });
  };

  return (
    <>
      <Styled.Container id="brandSection">
        <Title level="2" size={size}>
          {data.name}
        </Title>
        <Styled.WrapperCarousel>
          <Carousel
            id="brandSection"
            style={{ position: 'relative' }}
            swiperOptions={{
              breakpoints,
              preventClicks: true,
              preventClicksPropagation: true,
              spaceBetween: 10,
              direction: isMobile ? 'vertical' : 'horizontal',
              allowTouchMove: !isMobile,
            }}>
            {data.cards.length > 0 &&
              data.cards?.map((card, index: number) => (
                <Styled.Card key={`${index + 1}`}>
                  <img src={card.image.desktop.url} />
                  <Styled.Text>{card.name}</Styled.Text>
                </Styled.Card>
              ))}
          </Carousel>
        </Styled.WrapperCarousel>
        <Styled.Action>
          <Button
            id="brandSection-new-brand"
            isFullWidth
            variant="primary"
            onClick={handleClickOpenModal}>
            Quiero ser Aliado
          </Button>
        </Styled.Action>
      </Styled.Container>

      <ModalFormBrand open={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

export default memo(BrandSection);
