import useIsMobile from 'infrastructure/hooks/IsMobile/IsMobile';
import Button from 'presentation/components/atoms/Button';
import SmartLink from 'presentation/components/organisms/SmartLink';
import truncateText from 'presentation/utils/truncateText';
import Card from '../Card';

import Styled from './OfferCard.style';
import { OfferCardType } from './OfferCard.def';

type Props = {
  width: string;
  height?: string;
  heightImage?: string;
  data: OfferCardType;
  handleClick?: (decisionId: string) => void;
};

const OfferCard = (props: Props) => {
  const {
    width,
    height,
    heightImage,
    data: { image, title, subtitle, cta, url, target, decisionId, titleMobile },
    handleClick,
  } = props;

  const isMobile = useIsMobile();

  const TITLE = isMobile ? titleMobile : title;

  return (
    <Styled.WrapperCard width={width} height={height || '100%'}>
      <Card>
        <Styled.Header image={image} height={heightImage} />
        <Styled.Content>
          <Styled.Title>{truncateText(TITLE || '', 60)}</Styled.Title>
          <Styled.Description>
            {truncateText(subtitle || '', 200)}
          </Styled.Description>
        </Styled.Content>
        {cta && (
          <Styled.Footer width={width}>
            <SmartLink
              url={url}
              target={target}
              dyDecisionId={decisionId}
              onClick={() =>
                handleClick && decisionId && handleClick(decisionId)
              }>
              <Button>{truncateText(cta, 30)}</Button>
            </SmartLink>
          </Styled.Footer>
        )}
      </Card>
    </Styled.WrapperCard>
  );
};

export default OfferCard;
