import styled from 'styled-components';
import { CardCategoryProps, CardTitleProps } from './ReedemCard.def';

export const CardContainer = styled.div`
  height: 100%;
  max-height: 392px;
  display: grid;
  position: relative;
  /* grid-template-areas: 'head' 'body'; */
  grid-template-rows: 142px 250px;
  overflow: hidden;
  border-radius: 1em;
  border: 0.5px solid #c9d4df;
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 3px 6px -1.5px rgba(0, 51, 102, 0.1);

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

export const CardHead = styled.div`
  position: relative;
  /* grid-area: head; */
  border-bottom: 0.5px solid #c9d4df;
`;

export const CardCategory = styled.div<CardCategoryProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 16px;
  background: ${props => props.background};
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 6px 12px -3px rgba(0, 51, 102, 0.075);

  svg,
  img {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    width: 57px;
    height: 57px;
    left: 19px;

    svg,
    img {
      width: 34px;
      height: 28px;
    }
  }

  @media ${({ theme }) => theme.devices.desktop} {
    width: 57px;
    height: 57px;
    left: 19px;

    svg,
    img {
      width: 34px;
      height: 28px;
    }
  }

  @media ${({ theme }) => theme.devices.wideDesktop} {
    width: 61px;
    height: 61px;
    left: 20px;

    svg,
    img {
      width: 37px;
      height: 31px;
    }
  }
`;

export const CardListBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 16px;
  bottom: 0;
  margin-bottom: 16px;
  gap: 8px;
`;

export const CardBody = styled.div`
  /* grid-area: body; */
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: left;
  background: white;
`;

export const CardPartnerLogo = styled.div`
  position: absolute;
  top: -25px;
  left: 0px;
  width: 118px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 51, 102, 0.05),
    0px 9px 18px rgba(0, 51, 102, 0.05);
  border-radius: 0px 12px 12px 0px;

  margin-bottom: 16px;

  img {
    border-radius: inherit;
  }
`;

export const CardTitle = styled.h3<CardTitleProps>`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  line-height: 150%;
  letter-spacing: -0.01em;

  color: #323537;

  margin-top: ${props => (props.isCardPartnerLogo ? '28px' : '0px')};

  margin-bottom: 4px;
`;

export const CardDescription = styled.span`
  color: #63696e;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;

  color: #474c4e;
`;

export const CardPoints = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-basis: auto;

  p {
    margin: 0;
    color: #44474b;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    padding-right: 4px;
  }
`;
export const CardPointsBefore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-decoration-line: line-through;
    text-transform: uppercase;

    color: #474c4e;
  }

  svg {
    width: clamp(32px, 2.5vw, 34px);
    height: clamp(16px, 2.5vw, 16px);
  }
`;
export const CardPointsAfter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    color: #347b23;
  }

  svg {
    width: clamp(32px, 2.5vw, 46px);
    height: clamp(16px, 2.5vw, 22px);
    path {
      fill: #347b23;
    }
  }
`;
