import * as React from 'react';
import {
  useMemo,
  forwardRef,
  ForwardedRef,
  ComponentPropsWithRef,
  ReactChild,
} from 'react';

// Utils
import { ariaAttr } from 'presentation/utils/aria';

// Components
import HelpTip from 'presentation/components/atoms/HelpTip';

// Styles
import * as Styled from './Input.styles';
import InputFormat from './InputFormat';

// Definitions
type InputOptions = {
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  helpText?: string;
  addon?: ReactChild;
  formatter?: (value: string) => string;
};

type Omitted = 'disabled' | 'required';
export type Props = Omit<ComponentPropsWithRef<'input'>, Omitted> &
  InputOptions;

const Input = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    id,
    isDisabled,
    isInvalid,
    isRequired,
    helpText = '',
    addon,
    formatter,
    autoComplete,
    ...restInputOptions
  } = props;

  // Variables
  const testId = useMemo(() => id && `${id}-input`, [id]);
  const addonId = useMemo(() => id && `${id}-addon`, [id]);
  const helpId = useMemo(() => id && `${id}-help`, [id]);
  const withAddon = useMemo(() => !!addon, [addon]);
  const showHelpTooltip = useMemo(() => !!helpText, [helpText]);

  // Accessibility attributes
  const ariaAttributes = useMemo(
    () => ({
      ...ariaAttr('required', isRequired),
      ...ariaAttr('disabled', isDisabled),
      ...ariaAttr('invalid', isInvalid),
    }),
    [isRequired, isDisabled, isInvalid],
  );

  const InputElement = (
    <Styled.Input
      id={id}
      data-testid={testId}
      disabled={isDisabled}
      required={isRequired}
      autoComplete={autoComplete}
      ref={ref}
      {...restInputOptions}
      {...ariaAttributes}
    />
  );

  return (
    <Styled.InputGroup disabled={isDisabled} invalid={isInvalid}>
      {withAddon && (
        <Styled.InputAddon data-testid={addonId}>{addon}</Styled.InputAddon>
      )}
      {formatter ? (
        <InputFormat input={InputElement} formatter={formatter} ref={ref} />
      ) : (
        InputElement
      )}
      {showHelpTooltip && (
        <Styled.InputHelp>
          <HelpTip id={helpId} value={helpText} placement="left" />
        </Styled.InputHelp>
      )}
    </Styled.InputGroup>
  );
};

export default forwardRef(Input);
