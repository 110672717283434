import styled from 'styled-components';

const Container = styled.div`
  @media ${({ theme }) => theme.devices.desktop} {
    margin-top: 1rem;
  }
  .swiper {
    margin: 0 0 1rem 0;
    padding-bottom: 25px;

    .swiper-button-prev {
      margin-top: -2.5rem;
      margin-left: 1rem;
      padding: 16px;
    }
    .swiper-button-next {
      margin-top: -2.5rem;
      margin-right: 1rem;
      padding: 16px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      border-radius: 2rem;
      background-color: rgba(255, 255, 255, 0.7);
      &:hover {
        background-color: var(--color-primary);
        &:after {
          color: #fff;
        }
      }
    }
    &.mobile {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide {
      transform: translateZ(0);
      backface-visibility: hidden;
    }

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background-color: var(--color-btn-disabled);
        border-radius: 15px;
        margin-left: 8px;
        cursor: pointer;
        transition: opacity 0.3s, background-color 0.3s, width 0.3s;
        transition-delay: 0.3s, 0.3s, 0s;

        &.swiper-pagination-bullet-active {
          background: var(--color-primary);
          width: 24px;
          transition-delay: 0s;
        }
      }
    }
  }
`;

export default Container;
