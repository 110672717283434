import styled from 'styled-components';

const Styled = {
  WrapperCard: styled.div<{ width: string; height: string }>`
    position: relative;

    @media ${() => '(max-width: 424px)'} {
      width: 272px;
    }
    @media ${() => '(min-width: 425px)'} {
      width: ${({ width }) => width && width};
    }

    height: ${({ height }) => height && height};
  `,
  Header: styled.header<{ image: string; height?: string }>`
    background-image: ${({ image }) => image && `url(${image})`};
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: ${({ height }) => (height && height) || '129px'};
    position: relative;
  `,
  Content: styled.div`
    padding: 16px 24px 16px;
    @media ${() => '(max-width: 424px)'} {
      padding: 14px 22px 14px;
    }
    text-align: left;
    position: relative;
  `,
  Title: styled.p`
    font-weight: 600;
    line-height: 200%;
    color: #323537;
    text-align: left;
    letter-spacing: -0.01em;
    font-size: 16px;
    @media ${() => '(max-width: 424px)'} {
      font-size: 14px;
    }
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    letter-spacing: -0.02em;
    color: #323537;
    @media ${() => '(max-width: 424px)'} {
      font-size: 12px;
    }
  `,
  Footer: styled.div<{ width: string }>`
    @media ${() => '(max-width: 424px)'} {
      width: 272px;
    }
    @media ${() => '(min-width: 425px)'} {
      width: ${({ width }) => width && width};
    }

    display: flex;
    justify-content: end;
    a {
      text-decoration: none;
      margin: 0 25px 16px;
      @media ${() => '(max-width: 424px)'} {
        margin-bottom: 12px;
      }
    }

    button {
      border-radius: 128px;
      text-transform: inherit;
      width: fit-content;
      font-size: 14px;
      text-align: center;
      letter-spacing: -0.02em;
      @media ${() => '(max-width: 424px)'} {
        font-size: 12px;
      }
    }
  `,
};

export default Styled;
