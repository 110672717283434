import styled, { css } from 'styled-components';

type ContainerProps = {
  numberSlides: number;
};

const MAX_CENTERED_SLIDES = 4;

const Styled = {
  Container: styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 0 0 1rem;

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 16px 0 0 55px;
      gap: 1.5rem;
      align-items: center;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 16px 84px 0 84px;
      gap: 1.5rem;
      align-items: center;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      padding: 56px 82px 0 82px;
      gap: 1.5rem;
      align-items: center;
    }
  `,
  Title: styled.h1`
    font-weight: 400;
    font-size: 22px;
    color: #323537;

    @media ${({ theme }) => theme.devices.tablet} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      font-weight: 300;
      text-align: center;
      font-size: 32px;
      margin: 0 auto 32px;
    }
  `,
  WrapperCarousel: styled.div<ContainerProps>`
    width: 100%;
    height: 100%;
    max-width: 1200px;

    @media ${({ theme }) => theme.devices.mobile} {
      .swiper-wrapper {
        justify-content: initial;
      }

      .swiper-slide {
        max-height: 200px;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      .swiper-wrapper {
        justify-content: initial;
      }
      padding-left: 0px;

      .swiper-slide {
        max-height: 260px;
      }
    }

    @media ${() => '(min-width: 1180px)'} {
      .swiper-wrapper {
        ${({ numberSlides }) =>
          numberSlides <= MAX_CENTERED_SLIDES &&
          css`
            justify-content: space-between;
          `}
      }

      .swiper-slide {
        max-height: 260px;
        max-width: 260px;
      }
    }
  `,
  Card: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 200px;
    height: 200px;
    gap: 0.5rem;

    @media ${({ theme }) => theme.devices.tablet} {
      width: 230px;
      height: 230px;
      flex-direction: column;
      gap: 1.3rem;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      width: 260px;
      height: 260px;
      flex-direction: column;
      gap: 1.3rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: fill;
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #5c6166;

    @media ${({ theme }) => theme.devices.desktop} {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  `,
  BannerContainer: styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 18px 18px 44px 18px;

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 18px 55px 55px 55px;
      gap: 1.5rem;
      align-items: center;
    }

    @media ${({ theme }) => theme.devices.desktop} {
      padding: 18px 84px 64px 84px;
      gap: 1.5rem;
      align-items: center;
    }

    @media ${({ theme }) => theme.devices.wideDesktop} {
      gap: 1.5rem;
      align-items: center;
    }
  `,
  BannerEcoSystem: styled.section`
    width: 100%;
    max-width: 1200px;
    overflow: hidden;

    @media ${({ theme }) => theme.devices.mobile} {
      padding: 0;
      display: flex;
      justify-content: center;

      img {
        border-radius: 16px;
        width: 100%;
        object-fit: fill;
      }
    }

    @media (min-width: 575px) {
      display: flex;
      justify-content: center;

      img {
        border-radius: 16px;
        object-fit: fill;
        width: 100%;
      }
    }

    @media ${({ theme }) => theme.devices.tablet} {
      padding: 0;

      img {
        border-radius: 16px;

        object-fit: fill;
      }
    }

    @media (min-width: 980px) {
      height: 80px;
      padding: 0;
      img {
        border-radius: 16px;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    @media (min-width: 1040px) {
      padding: 0;

      img {
        border-radius: 16px;
        object-fit: fill;
      }
    }

    @media (min-width: 1240px) {
      padding: 0;
      height: 100px;

      img {
        border-radius: 16px;
        object-fit: fill;
      }
    }
  `,
};

export default Styled;
