import styled from 'styled-components';
import Button from 'presentation/components/atoms/Button';

const Styled = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    flex: 1;

    @media ${({ theme }) => theme.devices.desktop} {
      justify-content: space-between;
      align-items: center;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    height: 100%;
    width: 100%;
    @media ${({ theme }) => theme.devices.desktop} {
      justify-content: flex-start;
      align-items: self-start;
    }
  `,
  TitleFeedback: styled.div`
    font-size: 18px;
    font-weight: bold;
  `,
  WrapperButton: styled(Button)`
    padding: 0;
    a {
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
    }
  `,
};

export default Styled;
