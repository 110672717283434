import { memo } from 'react';

// Components
import { ICard, IImageResponsive } from 'domain/model/content';
import { Carousel } from 'presentation/components/molecules';
import useIsMobile from 'infrastructure/hooks/IsMobile/IsMobile';
import SmartLink from 'presentation/components/organisms/SmartLink/SmartLink';

import {
  EventTopicEnum,
  UiEventMessage,
  useEventBus,
} from 'infrastructure/eventBus';

// Styled Components
import Styled from './EcosystemSection.styled';

const MAX_CENTERED_SLIDES = 4;

const getBreakpoints = (numberSlides: number) => {
  return {
    260: { slidesPerView: 1.2, spaceBetween: 8 },
    320: { slidesPerView: 1.4, spaceBetween: 8 },
    375: { slidesPerView: 1.7, spaceBetween: 16 },
    480: { slidesPerView: 2.3, spaceBetween: 16 },
    520: { slidesPerView: 2.4, spaceBetween: 16 },
    600: { slidesPerView: 2.7, spaceBetween: 16 },
    700: { slidesPerView: 3.2, spaceBetween: 16 },
    768: { slidesPerView: 2.7, spaceBetween: 16 },
    900: { slidesPerView: 3.2, spaceBetween: 16 },
    992: { slidesPerView: 3.5, spaceBetween: 16 },
    1024: { slidesPerView: 3.2, spaceBetween: 16 },
    1135: { slidesPerView: 3.5, spaceBetween: 16 },
    1250: {
      slidesPerView: numberSlides > MAX_CENTERED_SLIDES ? 4 : 3.5,
      spaceBetween: numberSlides > MAX_CENTERED_SLIDES ? 24 : 0,
    },
  };
};

type EcosystemSectionProps = {
  data: { name: string; cards: ICard[] };
  banner: IImageResponsive;
};

const EcosystemSection = ({ data, banner }: EcosystemSectionProps) => {
  const isMobile = useIsMobile(['xs']);

  const { publish } = useEventBus<UiEventMessage>(EventTopicEnum.UI);

  const handleCardClick = (cardName: string) => {
    publish({
      module: 'seccion_ecosistema',
      action: 'card_ecosistem_clicked',
      subject: `Card ${cardName} has been clicked`,
      target: cardName,
    });
  };

  return (
    <>
      <Styled.Container id="ecosystemSection">
        <Styled.Title>{data.name}</Styled.Title>
        <Styled.WrapperCarousel numberSlides={data?.cards.length || 0}>
          <Carousel
            style={{ position: 'relative' }}
            id="ecosystemSection"
            swiperOptions={{
              breakpoints: getBreakpoints(data?.cards.length || 0),
              preventClicks: true,
              preventClicksPropagation: true,
              slidesOffsetAfter: 0,
            }}>
            {data.cards.length > 0 &&
              data.cards?.map(card => (
                <SmartLink
                  key={card.name}
                  url={card.link?.url || ''}
                  target={card.link?.external ? 'adaptable' : 'route'}
                  mode="stock">
                  <Styled.Card onClick={() => handleCardClick(card.name)}>
                    <img src={card.image.desktop.url} />
                  </Styled.Card>
                </SmartLink>
              ))}
          </Carousel>
        </Styled.WrapperCarousel>
      </Styled.Container>

      <Styled.BannerContainer>
        <Styled.BannerEcoSystem>
          <img
            src={isMobile ? banner.mobile.url : banner.desktop.url}
            alt="banner-cmr-puntos"
          />
        </Styled.BannerEcoSystem>
      </Styled.BannerContainer>
    </>
  );
};

export default memo(EcosystemSection);
