import styled from 'styled-components';
import { SlideProps } from 'presentation/components/molecules/ReedemCard/ReedemCard.def';

interface CarouselContainerProps {
  isCenter: boolean;
}

const Styled = {
  ExchangeExperiencesSection: styled.section`
    text-align: center;
    max-width: 100%;
    margin: 0 auto;

    @media ${() => '(min-width: 1280px)'} {
      max-width: 954px;
    }

    @media ${() => '(min-width: 1360px)'} {
      max-width: 1194px;
    }

    @media ${() => '(min-width: 1930px)'} {
      max-width: 1434px;
    }
  `,
  CarouselContainer: styled.div<CarouselContainerProps>`
    width: 100%;
    .swiper {
      padding-bottom: 7px;
      padding-left: 4px;
      padding-right: 4px;

      .swiper-wrapper {
        justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'initial')};
      }
    }
  `,
  WrapperCard: styled.div<SlideProps>`
    width: ${({ deviceoptions }) =>
      deviceoptions && deviceoptions.widthSizes.sm}px;
    height: 100%;
    max-height: 392px;
  `,
};

export default Styled;
