// TODO: revisar el tema de stopproagation

// Hooks
import useIsMobile from 'infrastructure/hooks/IsMobile';

// Component
import Button from 'presentation/components/atoms/Button';

import {
  Overlay,
  Background,
  TextContainer,
  ButtonContainer,
  Text,
  Title,
  Link,
  Container,
} from './Slide.style';

// Definitions
import { Props } from './Slide.def';

const Slide = (props: Props) => {
  const {
    title,
    description,
    link,
    color,
    image,
    onLoad = () => {},
    onClick = (target: string) => {},
  } = props;

  const { mobile, desktop } = image;

  // Hooks
  const isMobile = useIsMobile(['xs', 'sm']);
  const isHighDesktop = useIsMobile(['xl', 'xxl']);

  // Vars
  const DynamicContainer = link?.url ? Link : Container;
  return (
    <DynamicContainer
      url={link?.url ?? ''}
      target={link?.external ? 'adaptable' : 'route'}
      color={color}
      mode="stock"
      onClick={() => onClick('image')}>
      <Background
        src={isMobile ? mobile.url : desktop.url}
        alt={`Imagen ${title} ${description}`}
        draggable={false}
        mobile={isMobile}
        onLoad={onLoad}
      />
      {(title || description) && (
        <>
          <Overlay color={color} />
          <TextContainer
            color={color}
            mobile={isMobile}
            highDesktop={isHighDesktop}>
            {title && (
              <Title
                color={color}
                mobile={isMobile}
                highDesktop={isHighDesktop}>
                {title}
              </Title>
            )}
            {description && (
              <Text color={color} mobile={isMobile} highDesktop={isHighDesktop}>
                {description}
              </Text>
            )}
            {link?.text && link?.url && (
              <ButtonContainer mobile={isMobile} highDesktop={isHighDesktop}>
                <Button
                  variant="primary"
                  onClick={() => {
                    onClick('button');
                  }}
                  isFullWidth={isMobile}
                  size="M">
                  {link?.text?.slice(0, 20)}
                </Button>
              </ButtonContainer>
            )}
          </TextContainer>
        </>
      )}
    </DynamicContainer>
  );
};

export default Slide;
